import clsx from 'clsx';

export type SpinnerProps = {
  className?: string;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

function Spinner(props: SpinnerProps) {
  const {size, className} = props;
  const classNames = clsx(
    'border border-brage-violet border-t-violet-400 rounded-full animate-spin',
    size === 'xs' && 'w-3 h-3',
    size === 'sm' && 'w-6 h-6 border-2',
    size === 'md' && 'w-10 h-10 border-4',
    size === 'lg' && 'w-14 h-14 border-4',
    size === 'xl' && 'w-20 h-20 border-4',
    className,
  );
  return <div className={classNames} data-testid="spinner" />;
}

export {Spinner};
