import {DocumentUriTypeDto, MessageTypeDto} from '@brage/dto';
import {AxiosInstance} from 'axios';

export type SendInvoiceData = {
  documentNo: string;
  type: string;
};

export type SendMessageData = {
  accountNo: string;
  type: MessageTypeDto;
  itemID: string | number;
  filename: string;
};

export type SendDocumentData = {
  uri: string;
  uriType: DocumentUriTypeDto;
  filename: string;
};

export function apiMail(client: AxiosInstance) {
  return {
    postMailInstallmentDeferral: (data: {accountNo: string; from: string; months: number}) => {
      const url = `/mailgun/installment-deferral`;
      return client.post<boolean>(url, data);
    },
    postCompanyMailInstallmentDeferral: ({
      orgNo,
      data,
    }: {
      orgNo: string;
      data: {
        accounts: string;
        contactPerson: string;
        liquidity: string;
        longTerm: string;
        measures: string;
        months: number;
        from: string;
        otherCreditors: string;
        others: string;
        shortTerm: string;
      };
    }) => {
      const url = `/${orgNo}/mailgun/installment-deferral`;
      return client.post<boolean>(url, data);
    },
    postMailSendFile: (data: {
      to: string;
      subject?: string;
      template?: string;
      context?: any;
      blob: Blob;
      filename?: string;
    }) => {
      const formData = new FormData();
      formData.append('file', data.blob, data.filename);
      formData.append('to', data.to);
      if (data.subject) {
        formData.append('subject', data.subject);
      }
      if (data.template) {
        formData.append('template', data.template);
      }
      if (data.context) {
        formData.append('context', JSON.stringify(data.context));
      }
      return client.post<boolean>('/mailgun/send-file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    postMailSendInvoices: (
      data: {
        to: string;
        invoices: SendInvoiceData[];
      },
      orgNo?: string | null,
    ) => {
      const url = orgNo ? `/${orgNo}/mailgun/send-invoices` : `/mailgun/send-invoices`;
      return client.post<boolean>(url, data);
    },
    postMailSendMessages: (
      data: {
        to: string;
        messages: SendMessageData[];
      },
      orgNo?: string | null,
    ) => {
      const url = orgNo ? `/${orgNo}/mailgun/send-messages` : `/mailgun/send-messages`;
      return client.post<boolean>(url, data);
    },
    postMailSendDocuments: (data: {to: string; documents: SendDocumentData[]}) => {
      return client.post<boolean>('/mailgun/send-documents', data);
    },
  };
}
