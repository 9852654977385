import {useEffect} from 'react';
import {useFrontendConfig} from '../features/cms/use-frontend-config';

export default function useAppzi() {
  const {isAppziEnabled} = useFrontendConfig();

  useEffect(() => {
    let script: HTMLScriptElement;
    if (isAppziEnabled) {
      script = document.createElement('script');
      script.src = 'https://w.appzi.io/w.js?token=gSHn7';
      script.async = true;
      document.head.appendChild(script);
    }

    return () => {
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [isAppziEnabled]);
}
