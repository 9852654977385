export const accountPreview = {
  'Accounts.AccountNumber': 'Avtalenr',
  'Accounts.ProductType': 'Produkt',
  'Accounts.ObjectRegSer': 'Reg-/SerieNr',
  'Accounts.ObjectDescription': 'Objektbeskrivelse',
  'Accounts.TermAmount': 'Terminbeløp',
  'Accounts.EndDate': 'Sluttdato',
  'Accounts.NewestInvoice': 'Siste faktura',
  'Accounts.PDF': 'PDF',
  'Accounts.Payment': 'Til utbetaling',
  'Accounts.PartiallyPaid': 'Delutbetalt',
  'Accounts.Status': 'Status',
  'Accounts.DebtCollection': 'Inkasso',
};
