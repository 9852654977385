import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {sanityClient} from './sanity-client';
import {ClosedInfoData} from './types';

function useClosedInfoData(): UseQueryResult<ClosedInfoData> {
  const query = `
  *[_id == 'closed-info'] {
    "iconUrl": icon.asset->url,
    title,
    text,
  }[0]
  `;

  return useQuery(['closed-info-data'], () => sanityClient.fetch(query), {
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

export {useClosedInfoData};
