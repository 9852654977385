import toast, {ToastBar, Toaster} from 'react-hot-toast';
import {BiErrorCircle, BiCheck, BiInfoCircle, BiX} from 'react-icons/bi';

function ToastContainer() {
  return (
    <Toaster
      position="top-center"
      toastOptions={{
        className: 'text-lg',
        icon: <BiInfoCircle className="text-blue-500 h-6 w-6 shrink-0" />,
        success: {icon: <BiCheck className="text-green-600 h-6 w-6 shrink-0" />},
        error: {
          icon: <BiErrorCircle className="text-white h-6 w-6 shrink-0" />,
          style: {
            backgroundColor: '#dc2626', // text-red-600
            color: '#fff',
          },
        },
      }}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            maxWidth: '100%',
          }}
        >
          {({icon, message}) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <button
                  type="button"
                  className="rounded-md text-gray-400 hover:text-brage-black focus:text-brage-black focus:text-2xl focus:outline-none"
                  onClick={() => toast.dismiss(t.id)}
                >
                  <span className="sr-only">Lukk</span>
                  <BiX aria-hidden="true" />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

export {ToastContainer};
