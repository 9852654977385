function isGreaterThanZero(num: number | undefined | null): boolean {
  if (typeof num === 'number') {
    return num > 0;
  }
  return false;
}

function capitalize(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

// Clamp number between two values
function clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max);
}

function storeScrollY(scrollY?: number) {
  localStorage.setItem('scrollY', scrollY ? scrollY.toString() : window.scrollY.toString());
}

function resetScrollY() {
  const scrollY = localStorage.getItem('scrollY');
  if (scrollY) {
    const val = parseInt(scrollY);
    if (val > 0) {
      window.scrollTo(0, val);
    }
    clearScrollY();
  }
}

function clearScrollY() {
  localStorage.removeItem('scrollY');
}

function hasScrollY() {
  return localStorage.getItem('scrollY') !== null;
}

// min and max included
function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export {
  capitalize,
  clamp,
  clearScrollY,
  hasScrollY,
  isGreaterThanZero,
  randomIntFromInterval,
  resetScrollY,
  storeScrollY,
};
