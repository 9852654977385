import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {sanityClient} from './sanity-client';
import {ContactData} from './types';

function useContactData(): UseQueryResult<ContactData> {
  const query = `
  *[_id == 'contact'] {
    openingHours,
    phoneNumber,
    postalAddress{
      postalAddress,
      street
    },
    visitAddress{
      postalAddress,
      street
    },
  }[0]
  `;

  return useQuery(['contact-data'], () => sanityClient.fetch(query), {
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

export {useContactData};
