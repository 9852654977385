import React, {useMemo} from 'react';
import {
  browserName,
  isAndroid,
  isChrome,
  isEdge,
  isFirefox,
  isIOS,
  isOpera,
  isSafari,
  isSamsungBrowser,
} from 'react-device-detect';
import Item from './features/navigation/MenuItems';
import {ExclamationMark} from './icons/ExclamationMark';
import {AppContainer} from './layout/AppContainer';
import {Footer} from './layout/Footer';
import {Header} from './layout/Header';

export function BrowserNotSupported() {
  const alternativeBrowsers = useMemo(() => {
    const browsers = [];
    if (!isChrome) {
      browsers.push({name: 'Chrome', url: 'https://www.google.no/intl/no/chrome/'});
    }
    if (!isEdge) {
      browsers.push({name: 'Edge', url: 'https://www.microsoft.com/nb-no/edge/download'});
    }
    if (!isFirefox) {
      browsers.push({name: 'Firefox', url: 'https://www.mozilla.org/nb-NO/firefox/new/'});
    }
    if (!isOpera) {
      browsers.push({name: 'Opera', url: 'https://www.opera.com/no/download'});
    }
    return browsers;
  }, []);

  return (
    <AppContainer>
      <Header>
        <div className="sm:px-5 lg:px-10">
          <Item.HomeButton isAuthenticated={false} />
        </div>
      </Header>
      <main className="flex flex-col flex-grow justify-center items-center text-center px-5">
        <ExclamationMark />
        <h1 className="text-2xl my-5">Din nettleser støttes ikke</h1>
        <div className="max-w-lg">
          <p>For å bruke Min Side må du oppdatere nettleseren</p>
          <p>til den siste versjonen, eller prøve en annen nettleser.</p>
          <p className="mt-4">
            {`Informasjon for å oppdatere ${browserName} finner du `}
            <a
              className="underline font-semibold text-brage-link"
              href={getUpdateBrowserLink()}
              target="_blank"
              rel="noopener noreferrer"
            >
              her
            </a>
            .
          </p>
          {alternativeBrowsers.length > 0 && (
            <p className="mt-4">
              {'Alternativt kan du installere '}
              {alternativeBrowsers.map((browser, index) => (
                <React.Fragment key={index}>
                  <a
                    className="underline font-semibold text-brage-link"
                    href={browser.url}
                    target="_blank"
                  >
                    {browser.name}
                  </a>
                  {index === alternativeBrowsers.length - 2 && ' eller '}
                  {index < alternativeBrowsers.length - 2 && ', '}
                </React.Fragment>
              ))}
              .
            </p>
          )}
        </div>
      </main>
      <Footer />
    </AppContainer>
  );
}

function getUpdateBrowserLink() {
  if (isChrome) {
    if (isAndroid) {
      return 'https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DAndroid&oco=1';
    }
    if (isIOS) {
      return 'https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DiOS&oco=1';
    }
    return 'https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&oco=1';
  }
  if (isSafari) {
    return 'https://support.apple.com/no-no/HT204416';
  }
  if (isEdge) {
    return 'https://support.microsoft.com/nb-no/topic/oppdater-til-den-nye-microsoft-edge-182d0668-e3f0-49da-b8bb-db5675245dc2';
  }
  if (isFirefox) {
    return 'https://support.mozilla.org/no/kb/oppdater-firefox-nyeste-versjon';
  }
  if (isSamsungBrowser) {
    return 'https://www.samsung.com/no/support/apps-services/install-or-update-samsung-internet/';
  }
  if (isOpera) {
    return 'https://help.opera.com/en/latest/crashes-and-issues/#updateBrowser';
  }
  return 'https://www.google.no/';
}
