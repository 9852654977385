import {forwardRef, InputHTMLAttributes, useState} from 'react';

export type MonthsInputProps = {
  className?: string;
  type?: 'normal' | 'yellow';
  text?: React.ReactNode | string;
  error?: React.ReactNode | string;
  min?: number;
  max?: number;
} & InputHTMLAttributes<HTMLInputElement>;

const MonthsInput = forwardRef<HTMLInputElement, MonthsInputProps>((props, ref) => {
  const {
    className = '',
    error,
    type = 'normal',
    min = 1,
    max = Infinity,
    text = '',
    onChange,
    ...rest
  } = props;
  const [value, setValue] = useState<number | string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/\D+/g, '');
    onChange?.(e);

    const value = parseInt(e.target.value);
    if (value) {
      if (value > max) return setValue(max);
      if (value < min) return setValue(min);
      return setValue(value);
    }
    if (!value) return setValue('');
  };

  return (
    <>
      <div className="relative mb-6 inline-block">
        <input
          className={
            type === 'normal'
              ? `font-semibold block w-full py-2 border-gray-300 focus:outline-none focus:ring-violet-400 focus:border-violet-400 sm:text-sm rounded-md ${className}`
              : `bg-yellow-25 border-gray-200 rounded-sm w-full ${className}`
          }
          type="number"
          value={value}
          ref={ref}
          onChange={(e) => {
            handleChange(e);
          }}
          aria-label="Måneder"
          {...rest}
        />
        <p className="text-gray-400 absolute right-3 select-none pointer-events-none top-1/2 -translate-y-1/2">
          {text}
        </p>
      </div>
      {error && <p className="text-brage-red text-sm -mt-4">{error}</p>}
    </>
  );
});

export {MonthsInput};
