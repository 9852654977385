import * as Sentry from '@sentry/react';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './App';
import * as Environment from './env';
import './styles/index.css';
import {installPolyfills} from './utils';

// Do not initialize Sentry in dev.
if (!Environment.isDev()) {
  Sentry.init({
    dsn: Environment.sentryDsn(),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 0.0,
    environment: Environment.mode(),
  });
}

installPolyfills();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
