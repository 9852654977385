import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {sanityClient} from './sanity-client';
import {ErrorMessageData} from './types';

function useErrorMessageData(id: string): UseQueryResult<ErrorMessageData[]> {
  const query = `*[_id == '${id}'] {text}[0]`;
  return useQuery(['error-message-data', id], () => sanityClient.fetch(query), {
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

function fetchErrorMessageData(id: string): Promise<ErrorMessageData | null> {
  const query = `*[_id == '${id}'] {text}[0]`;
  return sanityClient.fetch(query);
}

export {fetchErrorMessageData, useErrorMessageData};
