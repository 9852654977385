function ExclamationMark({color}: {color?: string}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.753" height="81.5" viewBox="0 0 20.753 81.5">
      <g id="Group_2202" data-name="Group 2202" transform="translate(-171.182 -380.427)">
        <path
          id="Union_1"
          data-name="Union 1"
          d="M2.714,54.781A3.014,3.014,0,0,1,.082,51.687V42.4C-.071,27.393.009,18.814.173,14.133.065,10.691.082,7.954.082,7.052v-4.7A2.131,2.131,0,0,1,2.149,0H9.2a2.614,2.614,0,0,1,2.633,2.352V9.4c0,.46-.006.985-.016,1.565.023.653.016,1.372.016,2.135V51.687c0,1.7-.775,3.094-2.067,3.094Z"
          transform="translate(178.601 387.254)"
          fill={color ? color : '#ebddff'}
          stroke={color ? color : '#ebddff'}
          strokeWidth="3"
        />
        <g id="Group_2174" data-name="Group 2174" transform="translate(178.712 449.941)">
          <circle
            id="Ellipse_102"
            data-name="Ellipse 102"
            cx="5.993"
            cy="5.993"
            r="5.993"
            fill={color ? color : '#ebddff'}
          />
        </g>
        <path
          id="Path_1601"
          data-name="Path 1601"
          d="M40.523,59.634a7.051,7.051,0,1,0-7.051-7.051A7.051,7.051,0,0,0,40.523,59.634Z"
          transform="translate(139.21 398.744)"
          fill="none"
          stroke="#00002b"
          strokeWidth="3"
        />
        <path
          id="Union_2"
          data-name="Union 2"
          d="M2.714,54.781A3.014,3.014,0,0,1,.082,51.687V42.4C-.071,27.393.009,18.814.173,14.133.065,10.691.082,7.954.082,7.052v-4.7A2.131,2.131,0,0,1,2.149,0H9.2a2.614,2.614,0,0,1,2.633,2.352V9.4c0,.46-.006.985-.016,1.565.023.653.016,1.372.016,2.135V51.687c0,1.7-.775,3.094-2.067,3.094Z"
          transform="translate(172.683 381.927)"
          fill="none"
          stroke="#00002b"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}

export {ExclamationMark};
