import {ReactNode} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {MainContent} from './MainContent';
import {AppContainer} from './AppContainer';
import Item from '../features/navigation/MenuItems';
import {FlagPoster} from '../features/shared/FlagPoster';

type Props = {
  children: ReactNode;
};

function UnauthenticatedDefaultLayout(props: Props) {
  return (
    <AppContainer>
      <Header>
        <div className="sm:px-5 lg:px-10">
          <Item.HomeButton isAuthenticated={false} />
        </div>
      </Header>
      <FlagPoster type="login" />
      <MainContent>{props.children}</MainContent>
      <Footer />
    </AppContainer>
  );
}

export {UnauthenticatedDefaultLayout};
