import {Switch} from '@headlessui/react';
import clsx from 'clsx';
import {forwardRef} from 'react';

export type ToggleProps = {
  id: string;
  checked: boolean;
  disabled?: boolean;
  srLabel?: string;
  onChange: (checked: boolean) => void;
  onBlur?: (e: any) => void;
};

const Toggle = forwardRef<HTMLButtonElement, ToggleProps>((props, ref) => {
  const {id, checked, disabled, srLabel, onChange, onBlur} = props;
  return (
    <Switch
      id={id}
      disabled={disabled}
      checked={checked}
      onBlur={onBlur}
      onChange={onChange}
      className={clsx(
        checked ? 'bg-green-600' : 'bg-gray-200',
        'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50',
      )}
      ref={ref}
    >
      {srLabel && <span className="sr-only">{srLabel}</span>}
      <span
        aria-hidden="true"
        className={clsx(
          checked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        )}
      />
    </Switch>
  );
});

export {Toggle};
