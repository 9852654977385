import {forwardRef, InputHTMLAttributes} from 'react';

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'className'>;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  return (
    <input
      type="checkbox"
      className="focus:ring-gray-900 h-5 w-5 text-brage-black border-gray-900 border-2 rounded disabled:text-gray-500 disabled:border-gray-500 cursor-pointer"
      ref={ref}
      {...props}
    />
  );
});

export {Checkbox};
