import {UserDto} from '@brage/dto';
import {AxiosError} from 'axios';
import {useEffect, useState} from 'react';
import {api} from '../shared/data-fetching/api-client';
import {capitalize} from '../shared/utils';

function useCurrentUser() {
  const [user, setUser] = useState<UserDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [apiStatus, setApiStatus] = useState<number>();

  useEffect(() => {
    setIsLoading(true);
    api
      .getCurrentUser()
      .then((user) => {
        if (user) {
          // Normalize data here to avoid having to do it in every component that uses the user.
          user.firstName = capitalize(user.firstName.trim());
          user.lastName = capitalize(user.lastName.trim());

          // Remove sole proprietorships from the list of companies.
          user.companies = user.companies.filter((company) => company.orgNo !== user.ssn);

          user.companies.forEach((company) => {
            company.name = company.name.trim().toUpperCase();
          });

          user.companies.sort((a, b) => a.name.localeCompare(b.name));
        }
        setUser(user);
        setApiStatus(200);
      })
      .catch((err: AxiosError) => {
        setUser(null);
        setApiStatus(err.response?.status || 500);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return {user, isLoading, apiStatus};
}

export {useCurrentUser};
