import {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {useContactContext} from '../features/cms/ContactProvider';
import {useFrontendConfig} from '../features/cms/use-frontend-config';
import {Logo} from '../icons/Logo';

type Props = {
  children: ReactNode;
};

const Section = (props: Props) => <ul className="flex flex-col text-gray-600">{props.children}</ul>;
const SectionHeader = (props: Props) => <li className="font-bold">{props.children}</li>;
const SectionRow = (props: Props) => <li>{props.children}</li>;

function Footer({showFAQ}: Readonly<{showFAQ?: boolean}>) {
  const contact = useContactContext();
  const {isFAQEnabled} = useFrontendConfig();

  return (
    <footer className="bg-brage-purple lg:p-10">
      <div className="max-w-[1440px] p-6 space-y-4 lg:space-y-8 mx-auto">
        <Logo className="-ml-1 w-40 lg:w-48" />
        <div className="space-y-6 lg:flex  lg:space-y-0 lg:space-x-20 items-start">
          {contact && (
            <>
              {contact.postalAddress && (
                <Section>
                  <SectionHeader>
                    <FormattedMessage id="ContactDetails.PostalAddressHeading" />
                  </SectionHeader>
                  <SectionRow>{contact.postalAddress.street}</SectionRow>
                  <SectionRow>{contact.postalAddress.postalAddress}</SectionRow>
                </Section>
              )}
              {contact.visitAddress && (
                <Section>
                  <SectionHeader>
                    <FormattedMessage id="ContactDetails.VisitAddressHeading" />
                  </SectionHeader>
                  <SectionRow>{contact.visitAddress.street}</SectionRow>
                  <SectionRow>{contact.visitAddress.postalAddress}</SectionRow>
                </Section>
              )}
              {contact.phoneNumber && contact.openingHours && (
                <Section>
                  <SectionHeader>
                    <FormattedMessage id="ContactDetails.CustomerServiceHeading" />
                  </SectionHeader>
                  <SectionRow>
                    <a
                      href={`tel:+47${contact.phoneNumber.replace(/ /g, '')}`}
                      className="underline underline-offset-4 hover:font-semibold hover:underline-offset-1"
                    >
                      {contact.phoneNumber}
                    </a>
                    {` (${contact.openingHours})`}
                  </SectionRow>
                  {isFAQEnabled && showFAQ && (
                    <SectionRow>
                      <NavLink
                        to="/faq"
                        className="underline underline-offset-4 hover:font-semibold hover:underline-offset-1"
                      >
                        Ofte stilte spørsmål
                      </NavLink>
                    </SectionRow>
                  )}
                </Section>
              )}
            </>
          )}
        </div>
      </div>
    </footer>
  );
}

export {Footer};
