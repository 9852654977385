import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {IoIosInformationCircleOutline} from 'react-icons/io';
import {useQueryClient} from '@tanstack/react-query';
import {useLocation} from 'react-router';
import {FlagPosterData} from '../cms/types';
import {fetchErrorMessageData} from '../cms/use-error-message-data';
import {useFlagPosterData} from '../cms/use-flag-poster-data';

type FlagPosterType = 'general' | 'login' | 'privat' | 'bedrift';

const dployStatusCookieName = 'dploy-status';

function FlagPoster({type = 'general'}: {type?: FlagPosterType}) {
  const {data: flagPosterData} = useFlagPosterData();
  const {pathname} = useLocation();
  const [cookies] = useCookies([dployStatusCookieName]);
  const [text, setText] = useState<string | null>();

  const queryClient = useQueryClient();

  useEffect(() => {
    // Use general flag poster on company selector page
    const flagPosterType = pathname === '/company-selector' ? 'general' : type;
    const fp = getFlagPoster(flagPosterType, flagPosterData);
    if (fp) {
      setText(fp.text);
    } else if (cookies[dployStatusCookieName] && cookies[dployStatusCookieName] !== '200') {
      queryClient
        .fetchQuery(['error-message-dploy'], () => fetchErrorMessageData('error-message-dploy'))
        .then((res) => setText(res?.text))
        .catch(() => setText(null));
    } else {
      setText(null);
    }
  }, [cookies, flagPosterData, pathname, queryClient, type]);

  if (text) {
    return (
      <div className="bg-brage-violet w-full transition-all">
        <div className="max-w-[1600px] mx-auto px-8">
          <div className="flex items-center">
            <IoIosInformationCircleOutline className="text-3xl" />
            <p className="p-4 whitespace-pre-wrap">{text}</p>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

function getFlagPoster(type: FlagPosterType, data?: FlagPosterData[]) {
  let flagPoster = data?.find((fp) => fp._id === getFlagPosterId(type));
  // Fallback to general if no flag poster for type
  if (!flagPoster && type !== 'general') {
    flagPoster = data?.find((fp) => fp._id === getFlagPosterId('general'));
  }
  return flagPoster;
}

function getFlagPosterId(type: FlagPosterType) {
  switch (type) {
    case 'general':
      return 'flag-poster';
    case 'login':
      return 'flag-poster-login';
    case 'privat':
      return 'flag-poster-pm';
    case 'bedrift':
      return 'flag-poster-bm';
  }
}

export {FlagPoster};
