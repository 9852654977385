export function mode(): 'production' | 'test' | 'development' {
  if (import.meta.env.PROD) {
    if (window.location.href.startsWith('https://ms-test.brage.no')) {
      return 'test';
    }
    return 'production';
  }
  return 'development';
}

export function isProd(): boolean {
  return mode() === 'production';
}

export function isTest(): boolean {
  return mode() === 'test';
}

export function isDev(): boolean {
  return mode() === 'development';
}

export function sentryDsn(): string {
  return 'https://5731d13b469b4876bd947622df613557@o178653.ingest.sentry.io/6087435';
}

export function sanityProjectId(): string {
  switch (mode()) {
    case 'production':
      return 'p0d0hqco';
    case 'test':
      return 'tps0r1g1';
    default:
      return 'ry4b3rp9';
  }
}

export function sanityDataset(): string {
  return 'production';
}

export function sanityApiVersion(): string {
  return '2021-10-21';
}
