import {
  AddCompanyUserDto,
  ApplicationDocumentId,
  ApplicationDto,
  BuyoutDto,
  BuyoutReasonDto,
  ConsolidatedInvoiceDto,
  ContactDto,
  DocumentUriTypeDto,
  InvoiceDto,
  MessageTypeDto,
  MyPageFeaturesDto,
  PaginatedQueryOptions,
  PaginatedResponse,
  PaymentInsuranceAction,
  RedemptionDto,
  RoleName,
  SigningOrderDto,
  UpdateCompanyUserDto,
  UserDto,
} from '@brage/dto';
import axios, {AxiosError, AxiosInstance} from 'axios';
import {config} from '../config';
import {accountFetchers} from './api-accounts';
import {inboxFetchers} from './api-inbox';
import {apiMail} from './api-mail';

const client: AxiosInstance = axios.create({
  baseURL: config.api.baseURL,
  withCredentials: true,
  timeout: 90 * 1000, //90 seconds in order to support slow dploy and core connections
});

const getCurrentUserURL = '/auth/me';

client.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const isFetchCurrentUserRequest = error.config.url === getCurrentUserURL;
    if (
      !isFetchCurrentUserRequest &&
      (error.response?.status === 401 || error.response?.status === 503)
    ) {
      // Reload the app, which will load the current user, which will fail if you get 401 and require you to log back in.
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

const api = {
  logout: (): Promise<void> => client.get('/auth/logout'),
  getCurrentUser: (): Promise<UserDto | null> => {
    return client.get<UserDto>(getCurrentUserURL).then((r) => r.data);
  },
  getCompanyUsers: (orgNo: string) => {
    return client.get<UserDto[]>(`/${orgNo}/users`).then((r) => r.data);
  },
  addCompanyUser: (orgNo: string, payload: AddCompanyUserDto) => {
    return client.post<UserDto>(`/${orgNo}/users`, payload).then((r) => r.data);
  },
  removeCompanyUser: (orgNo: string, userId: string) => {
    return client.delete(`/${orgNo}/users/${userId}`).then((r) => r.data);
  },
  updateCompanyUser: (orgNo: string, userId: string, role: RoleName) => {
    const requestBody: UpdateCompanyUserDto = {role};
    return client.put<UserDto>(`/${orgNo}/users/${userId}`, requestBody).then((r) => r.data);
  },
  getApplications:
    (orgNo: string | null) =>
    (opts: PaginatedQueryOptions): Promise<PaginatedResponse<ApplicationDto>> => {
      const url = orgNo ? `/${orgNo}/applications` : '/applications';
      return client.get(url, {params: {skip: opts.skip, take: opts.take}}).then((r) => r.data);
    },
  getApplicationByApplicationNumber: async (applicationNumber: string, orgNo: string | null) => {
    const url = orgNo
      ? `/${orgNo}/applications/${applicationNumber}`
      : `/applications/${applicationNumber}`;
    return client
      .get<ApplicationDto | null>(url)
      .then((r) => r.data)
      .catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          return null;
        }
        throw err;
      });
  },
  postRedemption: async ({
    accountNo,
    orgNo,
    redemptionDueDate,
    originOfTheMeans,
  }: {
    accountNo: string;
    orgNo?: string | null;
    redemptionDueDate?: string;
    originOfTheMeans: string;
  }): Promise<RedemptionDto> =>
    client
      .post(
        `${orgNo ? `/${orgNo}` : ''}/accounts/${accountNo}/redemption`,
        {},
        {
          params: {
            redemptionDueDate,
            originOfTheMeans,
          },
        },
      )
      .then((r) => r.data),
  postBuyout: async ({
    orgNo,
    accountNo,
    buyoutDate,
    buyoutReason,
  }: {
    orgNo: string;
    accountNo: string;
    buyoutDate: string;
    buyoutReason: BuyoutReasonDto;
  }): Promise<BuyoutDto> =>
    client
      .post(
        `/${orgNo}/accounts/${accountNo}/buyout`,
        {},
        {
          params: {
            buyoutDate,
            buyoutReason,
          },
        },
      )
      .then((r) => r.data),
  createSignOrderForApplication: (applicationNumber: string, documentIds: string[]) =>
    client
      .post<SigningOrderDto>(`/signing/orders/${applicationNumber}`, {documentIds})
      .then((r) => r.data),
  createSigningOrderForSecurityDocument: (applicationNumber: string) =>
    client
      .post<SigningOrderDto>(`/signing/orders/${applicationNumber}/security-document`)
      .then((r) => r.data),
  getSigningOrders: (): Promise<PaginatedResponse<SigningOrderDto>> => {
    return client.get<PaginatedResponse<SigningOrderDto>>(`/signing/orders`).then((r) => r.data);
  },
  updateMaturity: ({months, accountNo}: {months: number; accountNo: string | number}) => {
    return client.put(
      '/accounts/maturity',
      {},
      {
        params: {
          months: months,
          accountNo: accountNo,
        },
      },
    );
  },
  getInvoices: ({
    accountNo,
    openOnly,
    dateFrom,
    orgNo,
  }: {
    accountNo?: string;
    dateFrom?: string;
    openOnly?: boolean;
    orgNo?: string | null;
  }) => {
    const url = `${orgNo ? `/${orgNo}` : ''}/invoices`;
    return client
      .get<Array<InvoiceDto | ConsolidatedInvoiceDto>>(url, {
        params: {
          accountNo,
          openOnly,
          dateFrom,
        },
      })
      .then((r) => r.data);
  },
  getContact: (ssnOrOrgNo: string, {isCompany}: {isCompany: boolean}) => {
    const url = isCompany ? `/${ssnOrOrgNo}/contacts/${ssnOrOrgNo}` : '/contacts/me';
    return client
      .get<ContactDto>(url)
      .then((r) => r.data)
      .catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          return null;
        }
        throw err;
      });
  },
  updatePersonalContact: (contact: ContactDto) => {
    return client.put<ContactDto>('/contacts/me', contact).then((r) => r.data);
  },
  updateCompanyContact: (orgNo: string, contact: ContactDto) =>
    client.put<ContactDto>(`/${orgNo}/contacts/${orgNo}`, contact).then((r) => r.data),
  updateDueDay: (accountNo: string, newDueDay: number) =>
    client.put(
      '/accounts/due-day',
      {},
      {
        params: {
          newDueDay: newDueDay,
          accountNo: accountNo,
        },
      },
    ),
  getMessagePDF: ({
    orgNo,
    accountNo,
    type,
    itemID,
  }: {
    orgNo?: string | null;
    accountNo: string | number;
    type: MessageTypeDto;
    itemID: string;
  }) => {
    return client
      .get<ArrayBuffer>(`${orgNo ? `/${orgNo}` : ''}/inbox/${accountNo}/${type}/${itemID}`, {
        responseType: 'arraybuffer',
      })
      .then((r) => r.data);
  },
  getInvoicePDF: ({
    orgNo,
    documentNo,
    documentType,
  }: {
    orgNo?: string | null;
    documentNo: string;
    documentType: string;
  }) => {
    return client
      .get<ArrayBuffer>(`${orgNo ? `/${orgNo}` : ''}/invoices/${documentNo}/${documentType}`, {
        responseType: 'arraybuffer',
      })
      .then((r) => r.data);
  },
  postApplicationPaymentInsurance: ({
    action,
    applicationNumber,
  }: {
    action: PaymentInsuranceAction;
    applicationNumber: string | number;
  }) => client.post(`/applications/${applicationNumber}/payment-insurance?action=${action}`),
  getMyPageFeatures: (): Promise<MyPageFeaturesDto> =>
    client.get(`/mypage/features`).then((r) => r.data),
  getDocument: ({
    orgNo,
    uri,
    uriType,
    filename,
  }: {
    orgNo?: string | null;
    uri: string;
    uriType: string | DocumentUriTypeDto;
    filename?: string;
  }) => {
    return client
      .get<ArrayBuffer>(`${orgNo ? `/${orgNo}/accounts` : '/accounts'}/document/download`, {
        params: {
          uri: uri,
          uriType: uriType,
          filename: filename,
        },
        responseType: 'arraybuffer',
      })
      .then((r) => r.data);
  },
  getApplicationDocument: ({
    applicationNumber,
    documentId,
  }: {
    applicationNumber: string;
    documentId: ApplicationDocumentId;
  }) => {
    return client
      .get<ArrayBuffer>(`/applications/${applicationNumber}/documents/${documentId}/download`, {
        responseType: 'arraybuffer',
      })
      .then((r) => r.data);
  },
  ...accountFetchers(client),
  ...inboxFetchers(client),
  ...apiMail(client),
};

export {api};
