export const account = {
  'Account.Error': 'Det oppstod en feil ved innlasting av avtalen.',
  'Account.AccountDetailsTitle': 'Avtaledetaljer',
  'Account.AccountNumber': 'Avtalenr',
  'Account.Date.ChooseMonth': 'Velg måned',
  'Account.Date.ChooseMonthPlaceholder': 'Velg måned her...',
  'Account.CollectiveInvoice': 'Samlefaktura',
  'Account.FundedAmount': 'Finansiert beløp',
  'Account.Insurance': 'Forsikring',
  'Account.Insurance.Payment': 'Betalingsforsikring',
  'Account.Insurance.PaymentDescription':
    'En betalingsforsikring gir deg og dine nærmeste økonomisk trygghet hvis noe uforutsett skulle inntreffe, slik som langvarig sykdom eller arbeidsledighet',
  'Account.Insurance.ObjectDescription':
    'Du har forsikring hos oss på denne avtalen.{br}{br}Forsikringen din i Brage Finans er en kaskoforsikring som omfatter ytre fysisk skade på forsikrede maskiner og utstyr som inntreffer plutselig og uforutsett. Forsikringen har en egenandel ved skade på kun kr {amount},-.',
  'Account.Insurance.ObjectBreadText.Leasing':
    'Se mer informasjon i forsikringsvilkårene under leasingavtalen.',
  'Account.Insurance.ObjectBreadText.Rental':
    'Se mer informasjon i forsikringsvilkårene under leieavtalen.',
  'Account.Insurance.ObjectReport': 'Meld skade / Kontakt oss',
  'Account.Insurance.PaymentRead': 'Meld skade',
  'Account.Interest.Type': 'Rentetype',
  'Account.Interest.Nominal': 'Nom. rente',
  'Account.Interest.Nibor': 'Nibor',
  'Account.Interest.Floating': 'Flytende',
  'Account.Information.Rental.Title': 'Leieopplysninger pr {date}',
  'Account.Information.Loan.Title': 'Låneopplysninger pr {date}',
  'Account.Information.Leasing.Title': 'Leasingopplysninger pr {date}',
  'Account.Invoices.Title': 'Siste faktura',
  'Account.Invoices.Empty': 'Du har ingen fakturaer for denne avtalen enda.',
  'Account.Documents.Title': 'Dokumenter',
  'Account.Documents.Title.Offer': 'Avtaledokumenter',
  'Account.Documents.Title.Supplier': 'Leverandørdokument',
  'Account.Documents.Empty':
    'Denne avtalen ser ut til å ha fysisk signerte dokumenter.{br}{br}Trenger du kopi av dokumentene tilsendt?{br}Ta kontakt med kundeservice.',
  'Account.Documents.GetUnsigned':
    'Trenger du kopi av usignerte dokumenter,{br}kausjonsdokumenter eller dokumenter signert fysisk?{br}{span}Ta kontakt med kundeservice, så hjelper vi deg.',
  'Account.LastInvoice': 'Siste faktura',
  'Account.LastInvoicePdf': 'PDF',
  'Account.Leasing.EndDate': 'Avtalen forlenges {term} til den kjøpes ut',
  'Account.Leasing.EndDate.Month': 'hver måned',
  'Account.Leasing.EndDate.Quarter': 'hver kvartal',
  'Account.Leasing.EndDate.HalfYear': 'hvert halvår',
  'Account.Leasing.EndDate.Year': 'hvert år',
  'Account.Loan.Title': 'Lånoversikt',
  'Account.Loan.ObjectPrice': 'Objektspris',
  'Account.Loan.LoanNextMaturity': 'Resterende lån',
  'Account.Loan.LoanNextMaturity.Tooltip':
    'Oppgitt beløp kan avvike fra innfrielsesbeløpet grunnet beregning av rente.',
  'Account.Loan.FundedAmount': 'Finansiert beløp',
  'Account.Loan.FundedAmount.Tooltip':
    'Finansiert beløp inkluderer eventuelle etablering- og tinglysingsgebyr.',
  'Account.Loan.RepayLoan': 'Innfri lån',
  'Account.LeasingOverview': 'Leasingoversikt',
  'Account.LeasingOverview.ExcludedMva': 'Alle beløp vises eks. mva',
  'Account.NotFound': 'Vi finner ikke avtalen du ser etter med avtalenummer {accountNo}',
  'Account.Maturity': 'Løpetid',
  'Account.Maturity.Terms': 'terminer',
  'Account.ChangeMaturity.Button': 'Endre løpetid',
  'Account.ChangeMaturity': 'Endre løpetid',
  'Account.ChangeMaturity.Error':
    'Ops her skjedde det en feil! Dersom problemet medvarer, ta kontakt med kundeservice. Kode: {status}',
  'Account.ChangeMaturity.Action': 'Endre løpetiden',
  'Account.ChangeMaturity.OneTermWarning':
    'Dersom du gjør denne endringen vil du ved neste fakturering motta faktura på hele det gjenstående lånebeløpet.',
  'Account.ChangeMaturity.Calculate': 'Kalkuler terminbeløp',
  'Account.ChangeMaturity.Description':
    'Regn ut hvor mye du må betale ved hver termin om du endrer nedbetalingstiden. \n\nEventuell betalingsforsikring vil komme i tillegg.',
  'Account.ChangeMaturity.RepayDate': 'Ønsket sluttdato',
  'Account.ChangeMaturity.RepaymentPeriod': 'Løpetid',
  'Account.ChangeMaturity.TermAmount': 'Terminbeløp',
  'Account.ChangeMaturity.Year': 'år',
  'Account.ChangeMaturity.MonthShort': 'mnd',
  'Account.ChangeMaturity.EstimatedPerMonth': '(estimert pr. termin.)',
  'Account.ChangeMaturity.Crown': 'kr',
  'Account.ChangeMaturity.Increase': 'Ønsker du øke løpetiden?',
  'Account.RemainingMaturity': 'Resterende løpetid',
  'Account.OriginalMaturity': 'Total løpetid',
  'Account.ObjectRegSer': 'Reg-/Serienr',
  'Account.ObjectPrice': 'Objektpris',
  'Account.ObjectDescription': 'Objektbeskrivelse',
  'Account.ProductType': 'Produkt',
  'Account.TermAmount': 'Terminbeløp',
  'Account.TermEndDate': 'Sluttdato',
  'Account.TermInformation': 'Terminopplysninger',
  'Account.TermFrequency': 'Terminfrekvens',
  'Account.Term.Custom': 'Hver {value} måned',
  'Account.Term.Monthly': 'Månedlig',
  'Account.Term.Quarterly': 'Kvartalsvis',
  'Account.Term.HalfYearly': 'Halvårlig',
  'Account.Term.Yearly': 'Årlig',
  'Account.IncludedInsuranceAmount': 'Inkl. forsikring',
  'Account.ExcludedInsuranceAmount': 'Ekskl. forsikring',
  'Account.Buyout.Title': 'Bestille utkjøp',
  'Account.Buyout.Month': 'Velg måned',
  'Account.Buyout.Order': 'Bestill',
  'Account.Buyout.Regret': 'Angre',
  'Account.Buyout.Month.ToolTip':
    'Utkjøp kan kun bestilles seks måneder frem i tid. Prisen forutsetter at det ikke skjer endringer i avtalens vilkår.',
  'Account.TermStartDate': 'Startdato',
  'Account.StartingRent': 'Startleie',
  'Account.DueDate': 'Forfallsdato',
  'Account.ChangeDueDate': 'Endre forfallsdato',
  'Account.YourAccount': 'Din avtale',
  'Account.SeeAllInvoices': 'Se alle faktura',
  'Account.Buyout.Link': 'Be om utkjøpstilbud',
  'Account.RepaymentPlan.Title': 'Betalingsplan',
  'Account.RepaymentPlan.TermDate': 'Termindato',
  'Account.RepaymentPlan.TermAmount': 'Terminbeløp',
  'Account.RepaymentPlan.Deduction': 'Avdrag',
  'Account.RepaymentPlan.InterestRate': 'Renter',
  'Account.RepaymentPlan.Fee': 'Gebyr',
  'Account.RepaymentPlan.PaymentInsurance': 'Forsikring',
  'Account.RepaymentPlan.PaymentInsuranceDescription':
    'Betalingsforsikring er ikke inkludert i terminbeløpet.',
  'Account.RepaymentPlan.ExtraDeduction': 'Ekstra avdrag',
  'Account.RepaymentPlan.ExtraDeductionsDescription':
    'Ekstra avdrag gjøres enten ved å legge beløpet til<br/>neste fakturabetaling eller bruke KID og<br/>kontonummer oppgitt på en tidligere faktura.<br/><br/>Ved ekstra innbetaling blir saldo på lånet redusert<br/>og fremtidige terminbeløp blir lavere.',
  'Account.RepaymentPlan.Balance': 'Restsaldo',
  'Account.RepaymentPlan.ShowAll': 'Vis alle',
  'Account.RepaymentPlan.ShowLess': 'Vis utdrag',
  'Account.NotFound.Title': 'Ingen avtale funnet',
  'Account.NotFound.Description': 'Finner ikke avtale {accountNo}',
  'Account.DebtCollection.Title': 'Denne avtalen har blitt sendt til inkasso.',
  'Account.DebtCollection.Description': 'Ta kontakt med {externalLink} for mer informasjon',
  'Account.OrderPaymentModal.Success': 'Søknad sendt',
  'Account.OrderPaymentModal.Success.Description':
    'Søknad om utkjøp er sendt din kundebehandler. Du vil motta svar på epost.',
  'Account.OrderPaymentModal.Success.Close': 'Ok',
  'Account.OrderPaymentModal.Failure': 'Fikk ikke sendt søknad',
  'Account.OrderPaymentModal.Failure.Description':
    'Vennligst ta kontakt med kundeservice dersom problemet medvarer.',
  'Account.OrderPaymentModal.Failure.Close': 'Ok',
  'Account.UpdateDueDay.Error':
    'Forfallsdato ble ikke oppdatert. Ta kontakt med kundeservice dersom problemet medvarer.',
  'Account.UpdateDueDay.Success': 'Forfallsdato er oppdatert',
  'Account.UpdateDueDay.Loading': 'Oppdaterer forfallsdato, vennligst vent',
  'Account.UpdateDueDay.Tooltip':
    'Endring av forfallsdato vil gjelde fra og med neste ikke-fakturerte termin.',
  'Account.Buyout.Leasing.Title': 'Utkjøp leasingavtale',
  'Account.Buyout.Rental.Title': 'Utkjøp leieavtale',
  'Account.Buyout.EndDate.Tooltip': 'Avtalen forlenges hver måned til den kjøpes ut',
  'Account.Buyout.BuyoutDate': 'Dato for utkjøp',
  'Account.Buyout.FetchBuyoutOffer': 'Hent utkjøpstilbud',
  'Account.Buyout.Help.Text': 'Trenger du ny finansiering? Vi kan hjelpe deg!',
  'Account.Buyout.Help.Button': 'Ja, takk! Send meg tilbud',
  'Account.Buyout.Reason.Title': 'Hvorfor ønsker du å kjøpe objektet?',
  'Account.Buyout.Reason.Sale': 'Videresalg av objekt',
  'Account.Buyout.Reason.Bank': 'Bytte finansieringspartner',
  'Account.Buyout.Reason.TradeIn': 'Innbytte til forhandler',
  'Account.Buyout.Reason.Saved': 'Tilgjengelige midler',
  'Account.Buyout.Reason.Insurance': 'Forsikringsoppgjør',
  'Account.Buyout.Offer.Line1':
    'Hvis utkjøpstilbudet ikke benyttes, sender vi neste faktura på vanlig måte.',
  'Account.Buyout.Offer.Line2':
    'Vi anser tilbudet som akseptert når fullt oppgjør er mottatt. Utkjøpsprisen forutsetter at dere betaler leasingleie frem til forfallsdato på utkjøpstilbudet.',
  'Account.Buyout.Offer.Download': 'Last ned utkjøpstilbud',
  'Account.Buyout.Offer.Send': 'Send på e-post',
  'Account.Buyout.Offer.Terms': 'Utkjøpsvilkår finner du ved å laste ned det fulle tilbudet.',
  'Account.Buyout.Error':
    'Det er dessverre ikke mulig å hente utkjøpstilbud på denne avtalen. Ta kontakt med kundeservice for mer informasjon.',
  'Account.Buyout.Error.Vendor':
    'Dersom du ønsker å kjøpe dette utstyret ber vi om at du tar kontakt direkte med leverandør.',
  'Account.View': 'Se avtalen',
};
