import {PrimaryButton} from '@brage/frontend-component-library';
import {FormattedMessage} from 'react-intl';
import {useRouteError} from 'react-router';
import {AppContainer} from '../../layout/AppContainer';
import {Footer} from '../../layout/Footer';
import {Header} from '../../layout/Header';
import Item from '../navigation/MenuItems';

function boldText(txt: any) {
  return <span className="font-semibold">{txt}</span>;
}

function ErrorPage() {
  const error = useRouteError() as any;
  return (
    <AppContainer>
      <Header>
        <div className="sm:px-5 lg:px-10">
          <Item.HomeButton isAuthenticated={false} />
        </div>
      </Header>
      <main className="flex flex-col gap-6 flex-grow justify-center items-center text-center">
        <h1 className="text-3xl">
          <FormattedMessage
            id="ErrorPage.Heading"
            values={{
              b: boldText,
            }}
          />
        </h1>
        {error && <h2 className="text-xl italic">{error.message}</h2>}
        <p className="">
          <FormattedMessage id="ErrorPage.Description" />
        </p>
        <PrimaryButton as="button" onClick={() => window.location.reload()}>
          <FormattedMessage id="ErrorPage.Action" />
        </PrimaryButton>
      </main>
      <Footer />
    </AppContainer>
  );
}

export {ErrorPage};
