import {ReactNode, createContext, useContext} from 'react';
import {ContactData} from './types';

type Props = {
  children: ReactNode;
  contact?: ContactData;
};

const ContactContext = createContext<ContactData | undefined>(undefined);

function ContactProvider(props: Props) {
  return <ContactContext.Provider value={props.contact}>{props.children}</ContactContext.Provider>;
}

function useContactContext() {
  return useContext(ContactContext);
}

export {ContactProvider, useContactContext};
