import {FullscreenLoader} from '@brage/frontend-component-library';
import {PortableText, PortableTextReactComponents} from '@portabletext/react';
import {useEffect} from 'react';
import SVG from 'react-inlinesvg';
import {useUserNotFoundData} from './features/cms/use-user-not-found';
import Item from './features/navigation/MenuItems';
import {api} from './features/shared/data-fetching/api-client';
import {AppContainer} from './layout/AppContainer';
import {Footer} from './layout/Footer';
import {Header} from './layout/Header';

export function UserNotFound() {
  const {data, isLoading} = useUserNotFoundData();

  // Make sure the user is logged out
  useEffect(() => {
    api.logout();
  }, []);

  if (isLoading) {
    return <FullscreenLoader />;
  }

  if (!data) {
    return null;
  }

  const isSvg = data.iconUrl?.endsWith('.svg');

  return (
    <AppContainer>
      <Header>
        <div className="sm:px-5 lg:px-10">
          <Item.HomeButton isAuthenticated={false} />
        </div>
      </Header>
      <main className="flex flex-col flex-grow justify-center items-center text-center px-5">
        {data?.iconUrl &&
          (isSvg ? (
            <SVG src={data.iconUrl} className="h-20 w-auto" strokeWidth={3} />
          ) : (
            <img src={`${data.iconUrl}?h=80`} className="h-20 w-auto" alt="" />
          ))}
        <h1 className="text-2xl my-5">{data?.title}</h1>
        <PortableText value={data?.text} components={PortableTextComponents} />
      </main>
      <Footer />
    </AppContainer>
  );
}

const PortableTextComponents: Partial<PortableTextReactComponents> = {
  marks: {
    link: ({value, children}) => (
      <a
        href={value.href}
        className="underline underline-offset-4 font-semibold text-brage-link hover:text-brage-blue hover:underline-offset-1"
      >
        {children}
      </a>
    ),
  },
  block: {
    h5: ({children}) => <p className="font-semibold">{children}</p>,
    normal: ({children}) => <p className="min-h-6">{children}</p>,
  },
  list: {
    bullet: ({children}) => <ul className="list-subtraction">{children}</ul>,
    number: ({children}) => <ol className="list-decimal">{children}</ol>,
  },
  listItem: {
    bullet: ({children}) => <li className="ml-4 my-6">{children}</li>,
    number: ({children}) => <li className="ml-4 my-6">{children}</li>,
  },
};
