import {UserDto} from '@brage/dto';
import {FullscreenLoader} from '@brage/frontend-component-library';
import * as Sentry from '@sentry/react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import React, {Suspense, useMemo} from 'react';
import {IntlProvider} from 'react-intl';
import {BrowserNotSupported} from './BrowserNotSupported';
import {InitialData} from './InitialData';
import {MyPageClosed} from './MyPageClosed';
import {UnauthenticatedApp} from './UnauthenticatedApp';
import {UserNotFound} from './UserNotFound';
import {ContactProvider} from './features/cms/ContactProvider';
import {nb} from './features/i18n/translations';
import {ToastContainer} from './features/notifications/ToastContainer';
import {ErrorPage} from './features/shared/ErrorPage';
import useAppzi from './hooks/useAppzi';
import {isBrowserSupported} from './utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {staleTime: Infinity, refetchOnWindowFocus: false, refetchOnReconnect: false},
  },
});

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));

function AppContent({user, apiStatus}: Readonly<{user: UserDto | null; apiStatus?: number}>) {
  useAppzi();
  const isSupported = useMemo(() => isBrowserSupported(), []);
  if (!isSupported) {
    return <BrowserNotSupported />;
  }
  if (apiStatus === 503) {
    return <MyPageClosed />;
  }
  if (apiStatus === 404) {
    return <UserNotFound />;
  }
  if (!user) {
    return <UnauthenticatedApp />;
  }
  return (
    <Suspense fallback={<FullscreenLoader />}>
      <AuthenticatedApp user={user} />
    </Suspense>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale="nb" messages={nb}>
        <InitialData>
          {({user, contact, apiStatus}) => (
            <ContactProvider contact={contact}>
              <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
                <AppContent user={user} apiStatus={apiStatus} />
                <ToastContainer />
              </Sentry.ErrorBoundary>
            </ContactProvider>
          )}
        </InitialData>
      </IntlProvider>
    </QueryClientProvider>
  );
}

export default App;
