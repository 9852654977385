import {ExclamationCircleIcon} from '@heroicons/react/solid';
import clsx from 'clsx';
import {forwardRef} from 'react';

export type TextAreaInputProps = {
  placeholder?: string;
  errorMessage?: string;
  className?: string;
};

const TextAreaInput = forwardRef<HTMLTextAreaElement, TextAreaInputProps>((props, ref) => {
  const {placeholder = '', errorMessage, className = '', ...rest} = props;
  return (
    <>
      <div className="relative">
        <textarea
          ref={ref}
          className={clsx(
            'border-gray-200 rounded-md w-full sm:min-h-20 h-36',
            errorMessage
              ? 'border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
              : 'focus:ring-violet-400 focus:border-violet-400 border-gray-300',
            !className.includes('bg-') && 'bg-yellow-25',
            className,
          )}
          placeholder={placeholder}
          {...rest}
        />
        {errorMessage && (
          <div className="absolute top-2 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-brage-red" aria-hidden="true" />
          </div>
        )}
      </div>
      {errorMessage && <p className="text-brage-red text-sm">{errorMessage}</p>}
    </>
  );
});

export {TextAreaInput};
