import {UserDto} from '@brage/dto';
import {FullscreenLoader} from '@brage/frontend-component-library';
import {useEffect} from 'react';
import toast from 'react-hot-toast';
import {useQueryClient} from '@tanstack/react-query';
import {useCurrentUser} from './features/auth/use-current-user';
import {ContactData} from './features/cms/types';
import {useContactData} from './features/cms/use-contact-data';
import {fetchErrorMessageData} from './features/cms/use-error-message-data';

interface InitialData {
  user: UserDto | null;
  contact?: ContactData;
  apiStatus?: number;
}

type Props = {
  children: (initialData: InitialData) => JSX.Element;
};

function InitialData(props: Props) {
  const {user, isLoading: isLoadingCurrentUser, apiStatus} = useCurrentUser();
  const {data: contact, isLoading: isLoadingContact} = useContactData();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (apiStatus === 504) {
      queryClient
        .fetchQuery(['error-message-login-timeout'], () =>
          fetchErrorMessageData('error-message-login-timeout'),
        )
        .then((res) => res?.text && toast.error(res.text));
    }
  }, [apiStatus, queryClient]);

  if (isLoadingCurrentUser || isLoadingContact) {
    return <FullscreenLoader />;
  }

  return props.children({
    user,
    contact,
    apiStatus,
  });
}

export {InitialData};
