export const inbox = {
  'Inbox.Error': 'Det oppstod en feil ved innlasting av meldinger.',
  'Inbox.Title': 'Dine meldinger',
  'Inbox.Description': 'Her finner du alle meldingene du har fått de siste ni månedene.',
  'Inbox.Received': 'Mottatt',
  'Inbox.Topic': 'Emne',
  'Inbox.Account': 'Avtale',
  'Inbox.PDF': 'PDF',
  'Inbox.Archive': 'Arkiverte meldinger',
  'Inbox.Archive.Description': 'Her finner du meldinger som er eldre enn ni måneder.',
};
