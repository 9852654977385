import {FullscreenLoader} from '@brage/frontend-component-library';
import SVG from 'react-inlinesvg';
import {useClosedInfoData} from './features/cms/use-closed-info-data';
import Item from './features/navigation/MenuItems';
import {AppContainer} from './layout/AppContainer';
import {Footer} from './layout/Footer';
import {Header} from './layout/Header';

export function MyPageClosed() {
  const {data, isLoading} = useClosedInfoData();

  if (isLoading) {
    return <FullscreenLoader />;
  }

  const isSvg = data?.iconUrl?.endsWith('.svg');

  return (
    <AppContainer>
      <Header>
        <div className="sm:px-5 lg:px-10">
          <Item.HomeButton isAuthenticated={false} />
        </div>
      </Header>
      <main className="flex flex-col flex-grow justify-center items-center text-center px-5">
        {data?.iconUrl &&
          (isSvg ? (
            <SVG src={data.iconUrl} className="h-20 w-auto" strokeWidth={3} />
          ) : (
            <img src={`${data.iconUrl}?h=80`} className="h-20 w-auto" alt="" />
          ))}
        <h1 className="text-2xl my-5">{data?.title}</h1>
        <div className="whitespace-pre-wrap text-center">
          <p>{data?.text}</p>
        </div>
      </main>
      <Footer />
    </AppContainer>
  );
}
