export const breadcrumbs = {
  'Breadcrumbs.Home': 'Hjem',
  'Breadcrumbs.Account': 'Din avtale',
  'Breadcrumbs.Accounts': 'Dine avtaler',
  'Breadcrumbs.Accounts.Archive': 'Avsluttede avtaler',
  'Breadcrumbs.Accounts.Repay': 'Innfri lån',
  'Breadcrumbs.Accounts.Installmentdeferral': 'Avdragsutsettelse',
  'Breadcrumbs.Accounts.Buyout': 'Utkjøp',
  'Breadcrumbs.Invoices': 'Faktura',
  'Breadcrumbs.Archive': 'Arkiv',
  'Breadcrumbs.Inbox': 'Dine meldinger',
  'Breadcrumbs.Inbox.Archive': 'Arkiverte meldinger',
  'Breadcrumbs.Settings': 'Innstillinger',
  'Breadcrumbs.Applications': 'Avtaler til signering',
  'Breadcrumbs.FAQ': 'Ofte stilte spørsmål',
};
