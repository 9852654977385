export const application = {
  'Application.Order.PaymentInsurance.Title': 'Betalingsforsikring',
  'Application.Order.PaymentInsurance.AccountDetails': 'Avtaledetaljer',
  'Application.Order.PaymentInsurance.AccountNo': 'Avtale nr',
  'Application.Order.PaymentInsurance.Product': 'Produkt',
  'Application.Order.PaymentInsurance.LoanAmount': 'Lånebeløp',
  'Application.Order.PaymentInsurance.Interest': 'Nom. rente',
  'Application.Order.PaymentInsurance.AcceptedOffer.Description':
    '<p><b>Du har gjort et trygt valg og takket ja til å ha betalingsforsikring på ditt lån hos oss. For å akseptere kjøp av betalingsforsikring knyttet til ditt lån må du bekrefte følgende;</b></p><b>Behovsavklaring og egenerklæring</b><p>Jeg ønsker å forsikre betalingsevnen for mitt lån i Brage i tilfelle jeg skulle få redusert inntekt på grunn av sykmelding, arbeidsledighet- eller permittering, og ønsker å sikre innfrielse av lånet ved tap av liv som følge av sykdom eller ulykke. Jeg ser derfor behov for å kjøpe betalingsforsikring som sikkerhet for mitt lån, og bekrefter at jeg;</p><ul><li>er over 18, men under 67 år, og har fast bosted i Norge og er medlem av norsk folketrygd eller tilsvarende ordning i Norden, og</li><li>er fast ansatt i minimum 50 % stilling eller er selvstendig næringsdrivende, og</li><li>ikke mottar sykepenger, AAP, dagpenger eller uføretrygd, og</li><li>ikke har mottatt personlig varsel om arbeidsledighet, eller varsel om permittering er gitt</li></ul><b>Den månedlige prisen på forsikringen utgjør 7,8% av ditt terminbeløp. Du kan lese mer om forsikringen <a1>her</a1></b>',
  'Application.Order.PaymentInsurance.AcceptedOffer.AcceptText':
    '<b>JA -</b> Jeg oppfyller vilkårene for forsikring ovenfor',
  'Application.Order.PaymentInsurance.AcceptedOffer.DeclineText':
    '<b>NEI -</b> Jeg er ikke kvalifisert til å tegne forsikring',
  'Application.Order.PaymentInsurance.Offer.Description':
    '<b>Hva er betalingsforsikring</b><p>Betalingsforsikring er en forsikring som dekker renter og avdrag på dette lånet dersom du skulle bli syk, permittert eller arbeidsledig. Ved død innfris restgjelden av lånet. Forsikring er frivillig, men vi anbefaler deg å tegne denne for dette lånet.</p><b>Hvorfor vi anbefaler forsikringen</b><p>Betalingsforsikring gir deg og din familie en økonomisk trygghet når du tar opp lån. Klikk <a1>her</a1> for å lese forkjøpsinformasjon, standardisert informasjonsdokument (IPID), vilkår og personvernerklæring.</p><b>Hva koster forsikringen</b><p>Betalingsforsikring koster 7,8% av ditt terminbeløp. Prisen legges til ditt ordinære terminbeløp, slik at du bare får en faktura på både lån og forsikring. Dersom ditt terminbeløp for eksempel er kr 1000, vil forsikringspremien som blir lagt til terminbeløpet være på kr 78. Totalt vil terminbeløpet inkludert forsikring bli da kr 1078.</p><b>Behovsavklaring og egenerklæring</b><p>Jeg ønsker å forsikre betalingsevnen for mitt lån i Brage i tilfelle jeg skulle få redusert inntekt på grunn av sykmelding, arbeidsledighet- eller permittering, og ønsker å sikre innfrielse av lånet ved tap av liv som følge av sykdom eller ulykke. Jeg ser derfor behov for å kjøpe betalingsforsikring som sikkerhet for mitt lån, og bekrefter at jeg;</p><ul><li>er over 18, men under 67 år, og har fast bosted i Norge og er medlem av norsk folketrygd eller tilsvarende ordning i Norden, og</li><li>er fast ansatt i minimum 50 % stilling eller er selvstendig næringsdrivende, og</li><li>ikke mottar sykepenger, AAP, dagpenger eller uføretrygd, og</li><li>ikke har mottatt personlig varsel om arbeidsledighet, eller varsel om permittering er gitt»</li></ul>',
  'Application.Order.PaymentInsurance.Offer.Question': 'Ønsker du betalingsforsikring?',
  'Application.Order.PaymentInsurance.Offer.AcceptText':
    '<b>Ja takk -</b> Jeg ønsker betalingsforsikring',
  'Application.Order.PaymentInsurance.Offer.AcceptTermsText':
    'Jeg oppfyller vilkårene for forsikring ovenfor.',
  'Application.Order.PaymentInsurance.Offer.DeclineText':
    '<b>Nei takk -</b> Jeg vil ikke ha betalingsforsikring',
  'Application.Order.PaymentInsurance.Confirm': 'Bekreft',
  'Application.Order.PaymentInsurance.Confirmed': 'Bekreftet',
  'Application.Order.PaymentInsurance.Success': 'Svaret ditt er lagret',
  'Application.Order.PaymentInsurance.Error':
    'En feil har oppstått. Vennligst prøv igjen senere og ta kontakt med kundeservice dersom problemet medvarer.',
};
