import {forwardRef, InputHTMLAttributes, ReactNode} from 'react';

export type RadioInputProps = {
  id: string;
  value: string;
  label?: ReactNode | string;
  errorMessage?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({id, value, label, errorMessage, ...props}, ref) => {
    return (
      <>
        <div className="mb-6 flex flex-row">
          <div className="w-12">
            <input
              id={id}
              type="radio"
              ref={ref}
              className="text-brage-black focus:outline-brage-black checked:ring-black"
              value={value}
              {...props}
            />
          </div>
          <label htmlFor={id}>{label}</label>
        </div>
        {errorMessage && <p className="text-brage-red text-sm -mt-4">{errorMessage}</p>}
      </>
    );
  },
);

export {RadioInput};
