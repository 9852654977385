import {ReactNode} from 'react';

type Props = {children: ReactNode; isListItem?: boolean; className?: string};

function MenuItem(props: Props) {
  return (
    <div
      className={`${props.isListItem ? 'flex-row' : 'flex-col justify-center'} flex items-center`}
    >
      {props.children}
    </div>
  );
}

function Icon(props: Props) {
  return <span className={`${props.className || ''} text-3xl`}>{props.children}</span>;
}

function Label(props: Props) {
  return (
    <span className={`${props.className || ''} text-xl lg:text-xs ml-6 lg:ml-0 lg:mt-1`}>
      {props.children}
    </span>
  );
}
function New({value}: {value: number}) {
  return value > 0 ? (
    <p className="absolute -top-2 -right-2 text-xs bg-brage-red text-white text-center rounded-full w-5 h-5 leading-5">
      {value > 9 ? '9+' : value}
    </p>
  ) : (
    <></>
  );
}

MenuItem.Icon = Icon;
MenuItem.Label = Label;
MenuItem.New = New;

export {MenuItem};
