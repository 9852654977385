function ManAndWoman(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.83325 70.1666C7.83325 64.1993 10.2038 58.4763 14.4233 54.2567C18.6429 50.0372 24.3659 47.6666 30.3333 47.6666C36.3006 47.6666 42.0236 50.0372 46.2432 54.2567C50.4627 58.4763 52.8333 64.1993 52.8333 70.1666"
        fill="#EBDDFF"
      />
      <path
        d="M16.5833 28.9166C16.5833 32.5634 18.0319 36.0607 20.6105 38.6393C23.1892 41.218 26.6865 42.6666 30.3333 42.6666C33.98 42.6666 37.4773 41.218 40.056 38.6393C42.6346 36.0607 44.0832 32.5634 44.0832 28.9166C44.0832 25.2699 42.6346 21.7725 40.056 19.1939C37.4773 16.6153 33.98 15.1666 30.3333 15.1666C26.6865 15.1666 23.1892 16.6153 20.6105 19.1939C18.0319 21.7725 16.5833 25.2699 16.5833 28.9166V28.9166Z"
        fill="#EBDDFF"
      />
      <path
        d="M47.5007 14.7199C49.4753 13.437 51.7462 12.6827 54.096 12.5292C56.4457 12.3758 58.7954 12.8283 60.9201 13.8436C63.0447 14.8588 64.8731 16.4026 66.2299 18.3271C67.5867 20.2516 68.4265 22.4923 68.6689 24.8346C68.9113 27.1768 68.5482 29.542 67.6142 31.7036C66.6803 33.8652 65.2068 35.7507 63.3351 37.1795C61.4634 38.6083 59.2562 39.5325 56.9248 39.8635C54.5935 40.1945 52.2162 39.9212 50.0207 39.0699"
        stroke="#00002B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 45.5567C53.2952 44.8102 56.7161 44.8145 60.0093 45.5695C63.3026 46.3244 66.3838 47.8106 69.0248 49.9179C71.6657 52.0252 73.7987 54.6997 75.2658 57.7432C76.7329 60.7868 77.4965 64.1214 77.5 67.5"
        stroke="#00002B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.4875 22.7799C51.11 25.0528 55.3011 26.2557 59.5775 26.2499C62.6861 26.2524 65.7624 25.6194 68.6176 24.3899"
        stroke="#00002B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 67.5C2.5 61.5326 4.87053 55.8097 9.0901 51.5901C13.3097 47.3705 19.0326 45 25 45C30.9674 45 36.6903 47.3705 40.9099 51.5901C45.1295 55.8097 47.5 61.5326 47.5 67.5"
        stroke="#00002B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2893 27.3331L11.2493 29.9265C11.3799 33.7636 10.3846 37.555 8.38599 40.8331"
        stroke="#00002B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.7475 26.6202V29.9536C38.6179 33.791 39.6145 37.5825 41.6142 40.8602"
        stroke="#00002B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2898 27.3331C13.8925 27.3169 16.4617 26.745 18.8256 25.6557C21.1894 24.5663 23.2933 22.9846 24.9965 21.0165C26.6981 22.9877 28.8013 24.5725 31.1652 25.6648C33.5291 26.757 36.0991 27.3315 38.7031 27.3498"
        stroke="#00002B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 26.25C11.25 29.8967 12.6987 33.3941 15.2773 35.9727C17.8559 38.5513 21.3533 40 25 40C28.6467 40 32.1441 38.5513 34.7227 35.9727C37.3013 33.3941 38.75 29.8967 38.75 26.25C38.75 22.6033 37.3013 19.1059 34.7227 16.5273C32.1441 13.9487 28.6467 12.5 25 12.5C21.3533 12.5 17.8559 13.9487 15.2773 16.5273C12.6987 19.1059 11.25 22.6033 11.25 26.25V26.25Z"
        stroke="#00002B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export {ManAndWoman};
