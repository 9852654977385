import clsx from 'clsx';
import {Button, ButtonProps} from './Button';

export type PrimaryButtonProps = ButtonProps;

function PrimaryButton(props: PrimaryButtonProps) {
  const {className, ...rest} = props;
  const classNames = clsx(
    'bg-brage-black text-white px-6 py-2 sm:py-3 rounded-full text-sm font-normal disabled:opacity-50 focus-visible:outline-brage-black',
    className,
  );
  return (
    <Button className={classNames} {...rest}>
      {props.children}
    </Button>
  );
}

export {PrimaryButton};
