import {Spinner} from './Spinner';

export type FullscreenLoaderProps = {
  label?: string;
};

function FullscreenLoader({label = 'Et øyeblikk...'}: FullscreenLoaderProps) {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center space-y-4">
      <div className="hidden md:block">
        <Spinner size="xl" />
      </div>
      <div className="block md:hidden">
        <Spinner size="md" />
      </div>
      <span>{label}</span>
    </div>
  );
}

export {FullscreenLoader};
