import {account} from './account';
import {accountInstallmentDeferral} from './account-installment-deferral';
import {accountPreview} from './account-preview';
import {accountRepayLoan} from './account-repay-loan';
import {accounts} from './accounts';
import {application} from './application';
import {breadcrumbs} from './breadcrumbs';
import {companySelector} from './company-selector';
import {dashboard} from './dashboard';
import {inbox} from './inbox';
import {settings} from './settings';

const nb = {
  ...dashboard,
  ...account,
  ...accountPreview,
  ...accountRepayLoan,
  ...accountInstallmentDeferral,
  ...settings,
  ...inbox,
  ...application,
  ...breadcrumbs,
  ...companySelector,
  ...accounts,
  'Common.Finance.Equity': 'Egenkapital',
  'Common.Leasing': 'Leasing',
  'Common.Loan': 'Lån',
  'Common.Rental': 'Leie',
  'Common.MonthShort': 'mnd',
  'Common.Download': 'Last ned',
  'Common.View.PDF': 'Se PDF',
  'Common.Currency.OriginalAmount': 'Opprinnelig beløp',
  'Common.OftenAskedQuestions': 'Ofte stilte spørsmål',
  'Common.DownloadFile.ServerError':
    'Det oppsto et problem. Vennligst prøv igjen senere. Kontakt kundeservice dersom problemet medvarer',
  'Currency.NOK.Crown': 'kr',
  'Currency.Interest.Nominal': 'Nom. rente',
  'Functionality.Disabled':
    'Denne funksjonaliteten er utilgjengelig for øyeblikket. Vennligst prøv igjen senere.',
  'Menu.ContextSelector.Helper':
    'Skal du sjekke avtalene for din bedrift? Trykk på knappen over for å se listen over bedriftene du har tilgang til.',
  'Menu.Button': 'Meny',
  'MenuItem.Admin': 'Admin',
  'MenuItem.Archive': 'Arkiv',
  'MenuItem.Invoice': 'Faktura',
  'MenuItem.Search': 'Søk',
  'MenuItem.Inbox': 'Meldinger',
  'MenuItem.Settings': 'Innstillinger',
  'MenuItem.Logout': 'Logg ut',
  'MenuItem.Accounts': 'Avtaler',
  'ErrorPage.Heading': '<b>Oisann!</b> Det oppstod en feil.',
  'ErrorPage.Description': 'Vi har loggført hendelsen og vil undersøke saken videre.',
  'ErrorPage.Action': 'Last siden på nytt',
  'UserForm.Labels.Ssn': 'Fødsels- og personnummer',
  'UserForm.Labels.Firstname': 'Fornavn',
  'UserForm.Labels.Lastname': 'Etternavn',
  'UserForm.AdminAccess.Label': 'Administrator hos Brage',
  'UserForm.AdminAccess.Description':
    'Gir mulighet til å administrere tilganger for alle brukere og selskaper.',
  'UserForm.Access.Heading': 'Tilganger',
  'UserForm.CancelButton': 'Avbryt',
  'UserForm.SubmitButton': 'Lagre',
  'CompanyUserForm.SsnLabel': 'Fødselsnummer',
  'CompanyUserForm.FirstnameLabel': 'Fornavn',
  'CompanyUserForm.LastnameLabel': 'Etternavn',
  'CompanyUserForm.RoleLabel': 'Rolle',
  'CompanyUserForm.EmptyRoleLabel': 'Velg...',
  'AddCompanyUserModal.SubmitError':
    'Det oppstod en feil! Kontakt kundeservice dersom problemet vedvarer.',
  'EditCompanyUserModal.SubmitError':
    'Det oppstod en feil! Kontakt kundeservice dersom problemet vedvarer.',
  'CompanyUsersAdmin.RemoveUser': 'Fjern tilgang',
  'CompanyUsersAdmin.EditUser': 'Rediger tilgang',
  'CompanyUsersAdmin.AddUser': 'Legg til bruker',
  'CompanyUsersAdmin.RemoveUserError':
    'Det oppstod en feil! Kontakt kundeservice dersom problemet vedvarer.',
  'FullscreenLoader.Description': 'Et øyeblikk...',
  'Applications.Heading': 'Avtaler til signering',
  'NoApplications.Title': 'Ingen avtaler til signering',
  'NoApplications.Description': 'Du har ingen avtaler som venter på din signatur :)',
  'Applications.Error': 'Det oppstod en feil ved innlasting av avtaler klare for signering.',
  'ApplicationDocuments.Submit':
    '{count, plural, =0 {Signer} one {Signer # dokument} other {Signer # dokumenter}}',
  'ApplicationProductType.Rental': 'Leie',
  'ApplicationProductType.Leasing': 'Leasing',
  'ApplicationProductType.Loan': 'Lån',
  'ObjectPriceLabel.Leasing': 'Objektspris eks. mva',
  'ObjectPriceLabel.Loan': 'Kjøpesum',
  'ObjectPriceLabel.Default': 'Objektspris',
  'ApplicationListItemDetails.LeasingEquity': 'Startleie eks. mva',
  'ApplicationListItemDetails.LoanEquity': 'Egenkapital',
  'ApplicationDocument.SecurityDocumentUnavailable.MissingRegNo':
    'Regnr./serienummer må oppgis til Brage Finans før signering',
  'ApplicationDocument.SecurityDocumentUnavailable.MissingLeasingContract':
    'Leasingavtalen må signeres før tinglysningsdokumentet signeres',
  'ApplicationDocument.SecurityDocumentUnavailable.MissingLoanContract':
    'Gjeldsbrev og betalingsplan må signeres før pantedokumentet signeres',
  'ApplicationDocument.LeasingConfirmationUnavailable':
    'Tinglysningsdokumentet må signeres før leveringsbeviset signeres',
  'ApplicationDocument.SecurityDocumentUnavailable.GenericError':
    'Dokumentet er ikke frigitt for signering ennå.',
  'ApplicationDocument.LeasingConfirmationUnavailable.WaitingForContractSigners':
    'Leveringsbeviset er først tilgjengelig for signering når alle signatarer har signert leasingavtalen.',
  'ApplicationDocument.DocumentUnavailable.GenericError':
    'Dokumentet er ikke tilgjengelig for signering ennå.',
  'ApplicationDocuments.Submitting': 'Forbereder signering',
  'ApplicationDocuments.MissingRequiredDocuments': `Du har valgt å signere ett eller flere dokumenter som krever at andre dokumenter enten må signeres samtidig, eller før dette dokumentet. <br />
      Følgende dokumenter må signeres samtidig:
        <b>{documents}</b>`,
  'ApplicationDocuments.SubmitError':
    'Beklager! Noe gikk galt da vi prøvde å starte signeringsprosessen.',
  'ApplicationDocuments.Signed': 'Signert',
  'ApplicationDocuments.Waiting.Header': 'Takk for signeringen!',
  'ApplicationDocuments.Waiting.Body':
    'Du har signert det du skal. Nå venter vi på signaturen til <b>{names}</b> før avtalen er ferdig signert.',
  'ConfirmObjectsReceivedModal.Heading': 'Er objektet mottatt?',
  'ConfirmObjectsReceivedModal.Confirm': '<b>JA</b> — Jeg har mottatt det finansierte objektet',
  'ConfirmObjectsReceivedModal.Deny':
    '<b>NEI</b> — Jeg har ikke mottatt noe og signerer leveringsbeviset når finansiert objekt er mottatt.',
  'ConfirmObjectsReceivedModal.Submit': 'Fortsett',
  'AcceptSecurityTermsModal.Heading': 'Informasjon',
  'AcceptSecurityTermsModal.Ok': 'OK',
  'AcceptSecurityTerms.Pantedokument.Description':
    'Vedlagt pantedokument må alltid signeres før overtakelse av finansiert gjenstand(er). Brage Finans AS vil ha pant i finansiert gjenstand(er) frem til lånet er nedbetalt i sin helhet.',
  'AcceptSecurityTerms.Tinglysningsdokument.Description':
    'Vedlagt tinglysningsdokument må alltid signeres før overtakelse av finansiert gjenstand(er). Brage Finans AS vil ha tinglyst eierskap i finansiert gjenstand(er) fram til leasingavtalen er avsluttet.',
  'SecurityDocumentListItem.Heading': 'Dette dokumentet må signeres separat',
  'SecurityDocumentExpired.Heading': 'Frist utgått',
  'SecurityDocumentExpired.Description':
    'Fristen for å signere er utgått. Ta kontakt med en kunderådgiver for mer informasjon.',
  'WaitingForSigningTask.Heading': 'Venter på at <b>{name}</b> skal signere først',
  'WaitingForSigningTask.ExpiresAt': '<b>{daysUntilExpiration}</b> dag(er) til fristen utgår',
  'SecurityDocumentListItem.Sign': 'Signer',
  'ErrorMessage.Generic': 'Beklager, noe gikk galt!',
  'ContactDetails.PostalAddressHeading': 'Postadresse',
  'ContactDetails.VisitAddressHeading': 'Besøksadresse',
  'ContactDetails.CustomerServiceHeading': 'Kundeservice',
  'InvoicesPage.Heading': 'Faktura',
  'InvoicesPage.Error': 'Det oppstod en feil ved innlasting av fakturaer.',
  'InvoiceList.DebtCollectionDescription': 'kontakt <link>Intrum</link>.',
  'NotFound.Title': 'Ojda! Her var det tomt!',
  'NotFound.DescriptionPartOne': `Denne siden har forsvunnet på et vis.`,
  'NotFound.DescriptionPartTwo': `Gå tilbake til den forrige siden.`,
  'NotFound.Back': 'Tilbake',
  'NoActiveAccounts.Title': 'Ingen aktive avtaler',
  'NoActiveAccounts.Description': 'Du har ingen aktive avtaler her enda',
  'NoArchivedAccounts.Title': 'Ingen arkiverte avtaler',
  'NoArchivedAccounts.Description': 'Du har ingen arkiverte avtaler her enda',
  'NoAccounts.PrivateHasCompany': 'Velg din bedrift for å se avtaler',
  'EmptyInbox.Title': 'Ingen meldinger',
  'EmptyInbox.Description': 'Du har ingen nye meldinger',
  'Invoice.DebtCollection.Title': 'En eller flere fakturaer har blitt sendt til inkasso.',
  'Invoice.DebtCollection.Description': 'Ta kontakt med {externalLink} for mer informasjon',
  'InvoiceListFilter.OpenOnly': 'Vis kun ubetalte',
  'InvoiceListItem.Heading.InvoiceNo': 'Fakturanr',
  'InvoiceListItem.Heading.AccountNo': 'Avtalenr',
  'InvoiceListItem.Heading.Object': 'Objekt',
  'InvoiceListItem.Heading.Amount': 'Opprinnelig beløp',
  'InvoiceListItem.Heading.RemainingAmount': 'Restbeløp',
  'InvoiceListItem.Heading.InvoiceDate': 'Fakturadato',
  'InvoiceListItem.Heading.DueDate': 'Forfallsdato',
  'InvoiceListItem.Heading.Status': 'Status',
  'NoInvoices.Title': 'Ingen fakturaer',
  'NoInvoices.Description': 'Du har ingen fakturaer her ennå',
  'NoUnpaidInvoices.Description': 'Du har ingen ubetalte fakturaer',
  'InvoiceListExcelExport.Title': 'Eksportér valgte fakturaer til Excel i CSV-format',
  'RoleDisplayName.Read': 'Lese',
  'RoleDisplayName.ReadWrite': 'Lese og endre',
  'RoleDisplayName.Admin': 'Administrator',
  'RoleDisplayName.Sign': 'Signere',
  'NoContact.Title': 'Ingenting å vise',
  'NoContact.Description': 'Du har ingen løpende avtaler ennå og dermed ingenting å se her :)',
  'ContactForm.NameLabel': 'Navn',
  'ContactForm.AddressLabel': 'Adresse',
  'ContactForm.ZipLabel': 'Postnr',
  'ContactForm.CityLabel': 'Sted',
  'ContactForm.MobilePhoneLabel': 'Mobil',
  'ContactForm.PhoneLabel': 'Telefon',
  'ContactForm.EmailLabel': 'E-post',
  'ContactForm.Submit': 'Lagre',
  'ContactForm.Submitting': 'Lagrer...',
  'ContactForm.RequiredError': 'Må fylles ut',
  'ContactForm.InvalidEmailError': 'Uyldig e-postadresse',
  'ContactForm.MustBeNumberError': 'Må være et nummer',
  'ContactForm.MinLengthError': 'Må inneholde min. {{#limit}} tegn.',
  'ContactForm.MaxLengthError': 'Må inneholde max. {{#limit}} tegn.',
  'ContactForm.LengthError': 'Må inneholde {{#limit}} tegn.',
  'ContactForm.InvalidSsnError': 'Ugyldig fødselsnummer',
  'ContactForm.SubmissionError':
    'Å nei! Det oppstod en feil når vi prøvde å oppdatere din brukerinformasjon. Ta kontakt med kundeservice dersom problemet vedvarer.',
  'ContactForm.InvalidZipError': 'Ugyldig postnummer',
};

export {nb};
