import {forwardRef, InputHTMLAttributes} from 'react';

export type SelectProps = InputHTMLAttributes<HTMLSelectElement> & {
  label?: string;
};

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {id, children, label, ...rest} = props;
  return (
    <div>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <select
        ref={ref}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-violet-400 focus:border-violet-400 sm:text-sm rounded-md"
        id={id}
        {...rest}
      >
        {children}
      </select>
    </div>
  );
});

export {Select};
