import * as Sentry from '@sentry/react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import {Login} from './features/auth/Login';
import {ErrorPage} from './features/shared/ErrorPage';
import {UnauthenticatedDefaultLayout} from './layout/UnauthenticatedDefaultLayout';

function UnauthenticatedAppLayout() {
  return (
    <UnauthenticatedDefaultLayout>
      <Login />
    </UnauthenticatedDefaultLayout>
  );
}

function UnauthenticatedApp() {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="*" element={<UnauthenticatedAppLayout />} errorElement={<ErrorPage />} />,
    ),
  );

  return <RouterProvider router={router} />;
}

export {UnauthenticatedApp};
