import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
};

function MainContent(props: Props) {
  return (
    <main className="flex flex-col grow w-full max-w-[1600px] mx-auto py-4 sm:p-8 lg:px-8 lg:py-16">
      {props.children}
    </main>
  );
}

export {MainContent};
