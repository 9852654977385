import {
  fullBrowserVersion,
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isMobileSafari,
  isOpera,
  isSafari,
  isSamsungBrowser,
  isYandex,
} from 'react-device-detect';
import {install as installResizeObserver} from 'resize-observer';

export function installPolyfills() {
  if (!window.ResizeObserver) {
    installResizeObserver();
  }

  if (![].at) {
    Array.prototype.at = function (pos) {
      return this.slice(pos, pos + 1)[0];
    };
  }
}

export function isBrowserSupported() {
  const versions = fullBrowserVersion.split('.');
  const majorVersion = parseInt(versions[0]);
  const minorVersion = parseInt(versions[1]);

  if (isChrome) {
    return majorVersion >= 85;
  }

  if (isMobileSafari) {
    return majorVersion >= 15 || (majorVersion >= 14 && minorVersion >= 5);
  }

  if (isSafari) {
    return majorVersion >= 15 || (majorVersion >= 14 && minorVersion >= 1);
  }

  if (isEdge) {
    return majorVersion >= 85;
  }

  if (isFirefox) {
    return majorVersion >= 77;
  }

  if (isSamsungBrowser) {
    return majorVersion >= 14;
  }

  if (isOpera) {
    return majorVersion >= 71;
  }

  if (isIE || isYandex) {
    return false;
  }

  // If we're unable to detect the browser,
  // we assume it's supported (in case of a new browser)
  return true;
}
