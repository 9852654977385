import {PrimaryButton} from '@brage/frontend-component-library';
import {useLocation} from 'react-router-dom';
import {ManAndWoman} from '../../icons/ManAndWoman';

function Login() {
  const {pathname} = useLocation();
  return (
    <div className="flex flex-col grow h-full justify-center items-center">
      <div className="rounded-lg border-4 border-brage-purple flex items-center flex-col py-16 px-16 sm:px-32 space-y-4 text-center">
        <ManAndWoman width={100} />
        <h1 className="text-xl">
          Velkommen til <span className="whitespace-nowrap">Min Side!</span>
        </h1>
        <PrimaryButton as="externalLink" href={`/api/auth/login?returnTo=${pathname}`}>
          Logg inn
        </PrimaryButton>
      </div>
    </div>
  );
}

export {Login};
