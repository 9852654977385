export const dashboard = {
  'Dashboard.ApplicationsForSigning': 'Avtaler du har til signering:',
  'Dashboard.Title': 'Dine avtaler',
  'Dashboard.DescriptionTitle': 'Velkommen til Min side!',
  'Dashboard.DescriptionContent': `Her finner du informasjon om ditt kundeforhold og avtaler.
  {br}{br}
  Du kan blandt annet hente ut kopi av faktura, finne saldo på lånet og øke det månedlige  terminbeløpet.`,
  'Dashboard.DescriptionContent.Company': `Her finner du informasjon om bedriftens kundeforhold og avtaler.
  {br}{br}
  Dere kan blandt annet hente kopi av faktura, be om utkjøpstilbud for leasingavtaler og innhente saldo på lån.
  {br}{br}
  Admin kan også tildele tilgang til {br}andre brukere.`,
  'Dashboard.Insurance': 'Kjøp forsikring',
  'Dashboard.Financing': 'Søk finansiering',
  'Dashboard.Application.Error': 'Det oppstod en feil ved innlasting av avtaler til signering.',
  'Dashboard.Mobile.ClickAccount': 'Trykk på en avtale for mer informasjon.',
  'Dashboard.Pagination.Text': 'Antall avtaler per side: ',
};
