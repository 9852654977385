import {ExclamationCircleIcon} from '@heroicons/react/solid';
import clsx from 'clsx';
import {forwardRef, InputHTMLAttributes} from 'react';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  id?: string;
  inputClassName?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  type?: 'normal' | 'yellow';
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    className = '',
    disabled,
    errorMessage,
    id,
    inputClassName = '',
    label,
    placeholder = '',
    value,
    type = 'normal',
    ...rest
  } = props;
  return (
    <div className={clsx('space-y-1 w-full', className)}>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="relative">
        <input
          id={id}
          value={value}
          disabled={disabled}
          type="text"
          ref={ref}
          aria-invalid={!!errorMessage}
          aria-describedby={errorMessage ? `${id}-error` : undefined}
          className={clsx(
            'h-10 border-gray-200 rounded-md box-border duration-300 w-full disabled:opacity-50',
            errorMessage
              ? 'border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
              : 'focus:ring-violet-400 focus:border-violet-400 border-gray-300',
            type === 'normal' && 'bg-white',
            type === 'yellow' && 'bg-yellow-25',
            inputClassName,
          )}
          placeholder={placeholder}
          {...rest}
        />
        {errorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-brage-red" aria-hidden="true" />
          </div>
        )}
      </div>
      {errorMessage && (
        <p id={`${id}-error`} role="alert" className="text-brage-red text-sm">
          {errorMessage}
        </p>
      )}
    </div>
  );
});

export {TextInput};
