import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {sanityClient} from './sanity-client';
import {UserNotFoundData} from './types';

export function useUserNotFoundData(): UseQueryResult<UserNotFoundData> {
  const query = `
  *[_id == 'user-not-found'] {
    "iconUrl": icon.asset->url,
    title,
    text,
  }[0]
  `;

  return useQuery(['user-not-found-data'], () => sanityClient.fetch(query), {
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });
}
