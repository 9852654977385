import {
  BiCog,
  BiEnvelope,
  BiLogOut,
  BiFolder,
  BiFile,
  BiWrench,
  BiSearchAlt2,
  BiCollection,
} from 'react-icons/bi';
import {MenuItem} from './MenuItem';
import {FormattedMessage} from 'react-intl';
import {ReactNode} from 'react';
import {Logo} from '../../icons/Logo';

type Props = {
  children: ReactNode;
};

type ItemProps = {
  isListItem?: boolean;
  hideLabel?: boolean;
};

type InboxItemProps = ItemProps & {messageCount: number};

type AccountsItemProps = ItemProps & {numberOfApplications: number};

function Item(props: Props) {
  return <div>{props.children}</div>;
}

function HomeButton({isAuthenticated}: {isAuthenticated: boolean}) {
  return (
    <Logo
      displayFlagOnlyOnSmallScreens={isAuthenticated}
      className={`${isAuthenticated ? 'w-12' : 'w-56'} md:w-56 -ml-0 md:ml-2 lg:-ml-4`}
    />
  );
}

function MenuButton({open}: {open: boolean}) {
  return (
    <MenuItem isListItem>
      <div className="block h-8 w-8  hover:cursor-pointer relative">
        <div
          className={`${
            open ? 'rotate-45 mt-0' : '-mt-2'
          }  absolute h-1 w-full top-2/4  bg-brage-black duration-500 -translate-y-2/4`}
        ></div>
        <div
          className={`${
            open ? 'scale-x-0' : 'scale-x-100'
          }  absolute h-1 w-full top-2/4  bg-brage-black duration-500 -translate-y-2/4`}
        ></div>
        <div
          className={`${
            open ? '-rotate-45 mt-0' : 'mt-2'
          } absolute h-1 w-full top-2/4 bg-brage-black duration-500 -translate-y-2/4`}
        ></div>
      </div>
    </MenuItem>
  );
}

function Admin({isListItem}: ItemProps) {
  return (
    <MenuItem isListItem={isListItem}>
      <MenuItem.Icon>
        <BiWrench />
      </MenuItem.Icon>
      <MenuItem.Label>
        <FormattedMessage id="MenuItem.Admin" />
      </MenuItem.Label>
    </MenuItem>
  );
}

function Archive({isListItem}: ItemProps) {
  return (
    <MenuItem isListItem={isListItem}>
      <MenuItem.Icon>
        <BiFolder />
      </MenuItem.Icon>
      <MenuItem.Label>
        <FormattedMessage id="MenuItem.Archive" />
      </MenuItem.Label>
    </MenuItem>
  );
}

function Invoice({isListItem, hideLabel}: ItemProps) {
  return (
    <MenuItem isListItem={isListItem}>
      <MenuItem.Icon className="relative">
        <BiFile />
      </MenuItem.Icon>
      {!hideLabel && (
        <MenuItem.Label>
          <FormattedMessage id="MenuItem.Invoice" />
        </MenuItem.Label>
      )}
    </MenuItem>
  );
}

function Search({isListItem, hideLabel}: ItemProps) {
  return (
    <MenuItem isListItem={isListItem}>
      <MenuItem.Icon>
        <BiSearchAlt2 />
      </MenuItem.Icon>
      {!hideLabel && (
        <MenuItem.Label>
          <FormattedMessage id="MenuItem.Search" />
        </MenuItem.Label>
      )}
    </MenuItem>
  );
}

function Inbox({isListItem, messageCount}: InboxItemProps) {
  return (
    <MenuItem isListItem={isListItem}>
      <MenuItem.Icon className="relative">
        <MenuItem.New value={messageCount} />
        <BiEnvelope />
      </MenuItem.Icon>
      <MenuItem.Label>
        <FormattedMessage id="MenuItem.Inbox" />
      </MenuItem.Label>
    </MenuItem>
  );
}

function Settings({isListItem}: ItemProps) {
  return (
    <MenuItem isListItem={isListItem}>
      <MenuItem.Icon>
        <BiCog />
      </MenuItem.Icon>
      <MenuItem.Label>
        <FormattedMessage id="MenuItem.Settings" />
      </MenuItem.Label>
    </MenuItem>
  );
}

function Logout({
  isListItem,
  className,
}: {
  className?: {icon?: string; label?: string};
  isListItem?: ItemProps['isListItem'];
}) {
  return (
    <MenuItem isListItem={isListItem}>
      <MenuItem.Icon className={className?.icon}>
        <BiLogOut />
      </MenuItem.Icon>
      <MenuItem.Label className={className?.label}>
        <FormattedMessage id="MenuItem.Logout" />
      </MenuItem.Label>
    </MenuItem>
  );
}

function Accounts({isListItem, numberOfApplications}: AccountsItemProps) {
  return (
    <MenuItem isListItem={isListItem}>
      <MenuItem.Icon className="relative">
        <MenuItem.New value={numberOfApplications} />
        <BiCollection />
      </MenuItem.Icon>
      <MenuItem.Label>
        <FormattedMessage id="MenuItem.Accounts" />
      </MenuItem.Label>
    </MenuItem>
  );
}

Item.HomeButton = HomeButton;
Item.MenuButton = MenuButton;
Item.Admin = Admin;
Item.Archive = Archive;
Item.Invoice = Invoice;
Item.Search = Search;
Item.Inbox = Inbox;
Item.Settings = Settings;
Item.Logout = Logout;
Item.Accounts = Accounts;

export default Item;
