import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

type BaseProps = {
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
};

type ButtonAsButton = BaseProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    as: 'button';
  };

type ButtonAsLink = BaseProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  LinkProps & {
    as: 'link';
  };

type ButtonAsExternal = BaseProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    as: 'externalLink';
  };

export type ButtonProps = ButtonAsButton | ButtonAsExternal | ButtonAsLink;

function Button(props: ButtonProps) {
  switch (props.as) {
    case 'externalLink':
      return props.disabled ? <span {...props} /> : <a {...props} />;
    case 'link':
      return props.disabled ? <span {...props} /> : <Link {...props} />;
    case 'button':
      return <button {...props} />;
    default:
      return <button />;
  }
}

export {Button};
