import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {sanityClient} from './sanity-client';
import {FrontendConfig} from './types';

export function useFrontendConfigData(): UseQueryResult<FrontendConfig> {
  const query = `*[_id == 'frontend-config'][0]`;
  return useQuery(['frontend-config'], () => sanityClient.fetch(query), {
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

export function useFrontendConfig(): FrontendConfig {
  const {data} = useFrontendConfigData();
  return (
    data ?? {
      isAppziEnabled: false,
      isFAQEnabled: false,
      isChatEnabled: false,
      idleLogoutTimeMin: 0,
    }
  );
}
