import clsx from 'clsx';
import {forwardRef, InputHTMLAttributes} from 'react';

export type SelectInputProps = InputHTMLAttributes<HTMLSelectElement> & {
  type?: 'normal' | 'yellow';
  className?: string;
  error?: React.ReactNode | string;
};

const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>((props, ref) => {
  const {value, children, error, type = 'normal', className = '', ...rest} = props;
  return (
    <div className="flex flex-col gap-1">
      <select
        value={value}
        ref={ref}
        className={clsx(
          'flex-1 focus:outline-none focus:ring-violet-400 focus:border-violet-400',
          type === 'normal' && 'block py-2 border-gray-300 sm:text-sm rounded-md',
          type === 'yellow' && 'bg-yellow-25 border-gray-200 rounded-sm w-full box-border',
          className,
        )}
        {...rest}
      >
        {children}
      </select>
      {error && (
        <p className="text-brage-red text-sm" role="alert">
          {error}
        </p>
      )}
    </div>
  );
});

export {SelectInput};
