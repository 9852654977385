export const accountInstallmentDeferral = {
  'Account.InstallmentDeferralModal': 'Avdragsutsettelse',
  'Account.InstallmentDeferralModal.Months': 'mnd',
  'Account.InstallmentDeferralModal.Months.Amount': 'Antall',
  'Account.InstallmentDeferralModal.From': 'Fra',
  'Account.InstallmentDeferralModal.From.Error': 'Velg en av alternativene',
  'Account.InstallmentDeferralModal.Months.Error': 'Må fylles ut',
  'Account.InstallmentDeferralModal.Description':
    'Velg antall måneder og fra når du ønsker avdragsutsettelser. Det påløper et gebyr på 250 kroner ved innvilging av utsettelse.',
  'Account.InstallmentDeferralModal.Button': 'Søk om avdragsutsettelse',
  'Account.InstallmentDeferralModal.Success': 'Søknad sendt',
  'Account.InstallmentDeferralModal.Success.Description':
    'Søknad om avdragsutsettelse er sendt din kundebehandler. Du vil motta svar på epost.',
  'Account.InstallmentDeferralModal.Success.Close': 'Ok',
  'Account.InstallmentDeferralModal.Failure': 'Fikk ikke sendt søknad',
  'Account.InstallmentDeferralModal.Failure.Description':
    'Vennligst ta kontakt med kundeservice dersom problemet medvarer.',
  'Account.InstallmentDeferralModal.Failure.Close': 'Ok',
  'Account.InstallmentDeferral': 'Avdragsutsettelse',
  'Account.InstallmentDeferral.Description':
    'Før vi kan behandle saken ønsker vi avklaring fra dere.',
  'Account.InstallmentDeferral.WhichAccounts': 'Hvilke avtaler gjelder det?',
  'Account.InstallmentDeferral.AllAccounts': 'Alle',
  'Account.InstallmentDeferral.SomeAccounts': 'Noen',
  'Account.InstallmentDeferral.SomeAccounts.Accounts': 'Gjelder følgende avtaler',
  'Account.InstallmentDeferral.SomeAccounts.AccountsPlaceholder': '123456, 234567, etc',
  'Account.InstallmentDeferral.CompanyMeasures':
    'Hvilke tiltak har selskapet iverksatt for å komme gjennom denne situasjonen?',
  'Account.InstallmentDeferral.Prospects': 'Hvordan vurderer dere selskapets fremtidsutsikter?',
  'Account.InstallmentDeferral.Prospects.LongTerm': 'Lang sikt',
  'Account.InstallmentDeferral.Prospects.ShortTerm': 'Kort sikt',
  'Account.InstallmentDeferral.Liquidity':
    'Planlegger eierne å tilføre ny kapital for å bedre likviditeten?',
  'Account.InstallmentDeferral.Liquidity.Yes': 'Ja',
  'Account.InstallmentDeferral.Liquidity.No': 'Nei',
  'Account.InstallmentDeferral.Liquidity.Comment': 'Kommentar:',
  'Account.InstallmentDeferral.OtherCreditors': 'Har dere søkt om utsettelse hos andre kreditorer?',
  'Account.InstallmentDeferral.OtherCreditors.Yes': 'Ja',
  'Account.InstallmentDeferral.OtherCreditors.Which': 'Hvilke er dette?',
  'Account.InstallmentDeferral.OtherCreditors.No': 'Nei',
  'Account.InstallmentDeferral.Months': 'Hvor mange måneder ønskes avdragsutsettelse?',
  'Account.InstallmentDeferral.Months.Amount': 'Antall',
  'Account.InstallmentDeferral.Months.Amount.Months': 'mnd',
  'Account.InstallmentDeferral.Months.From': 'Fra',
  'Account.InstallmentDeferral.Other': 'Evt. andre opplysninger',
  'Account.InstallmentDeferral.ContactPerson': 'Informasjon til kontaktperson',
  'Account.InstallmentDeferral.ContactInformation': 'Epost/telefon',
  'Account.InstallmentDeferral.Submit': 'Send søknad',
  'Account.InstallmentDeferral.Submit.Modal.Title': 'Takk for søknaden!',
  'Account.InstallmentDeferral.Submit.Modal.Description':
    'Du blir kontaktet av en av våre kundebehandlere.',
  'Account.InstallmentDeferral.Submit.Modal.Close': 'Lukk',
  'Account.InstallmentDeferral.Error.String': 'Må fylles ut',
  'Account.InstallmentDeferral.Error.Select': 'Velg en av alternativene over',
  'Account.InstallmentDeferral.Error.Number': 'Må fylles ut med tall',
  'Account.InstallmentDeferral.Mail.Subject': 'Avdragsutsettelse bedrift: ',
  'Account.InstallmentDeferral.Mail.Yes': 'Ja',
  'Account.InstallmentDeferral.Mail.No': 'Nei',
  'Account.InstallmentDeferral.Mail.Company': 'Bedrift',
  'Account.InstallmentDeferral.Mail.Private': 'Privat',
};
