export const accountRepayLoan = {
  'RepayLoan.Title': 'Innfri lån',
  'RepayLoan.Back': 'Tilbake',
  'RepayLoan.Date': 'Dato for innfrielse',
  'RepayLoan.Reason': 'Hvorfor innfrir du lånet?',
  'RepayLoan.Reason.Sale': 'Salg av objekt',
  'RepayLoan.Reason.Mortage': 'Inn i boliglån',
  'RepayLoan.Reason.Bank': 'Bytte bank',
  'RepayLoan.Reason.Reduce': 'Redusere total lån',
  'RepayLoan.Reason.Interest': 'Høy rente',
  'RepayLoan.Origin': 'Hvor kommer pengene fra?',
  'RepayLoan.Origin.ToolTip':
    'I henhold til hvitvaskingsregelverket må vi<br/>gjennomføre tiltak for å forhindre hvitvasking,<br/>og kjennskap til midlers opprinnelse er et slikt tiltak.',
  'RepayLoan.Origin.Savings': 'Oppsparte midler',
  'RepayLoan.Origin.Exchange': 'Innbytte',
  'RepayLoan.Origin.BusinessProfits': 'Overskudd fra bedrift',
  'RepayLoan.Origin.Heritage': 'Arv',
  'RepayLoan.Origin.InsuranceSettlement': 'Forsikringsoppgjør',
  'RepayLoan.Origin.Winnings': 'Lotteri/Vunnet penger',
  'RepayLoan.Payment': 'Hent betalingsinformasjon',
  'RepayLoan.Payment.ChangedDate': 'Dato er justert til neste bankdag.',
  'RepayLoan.Payment.Information': 'Din betalingsinfo',
  'RepayLoan.Payment.Amount': 'Beløp',
  'RepayLoan.Payment.DueDate': 'Forfall',
  'RepayLoan.Payment.AccountNumber': 'Kontonr',
  'RepayLoan.Payment.KIDNumber': 'Kidnr',
  'RepayLoan.Payment.Expires': 'Hvis lånet ikke innfris, sender vi neste faktura på vanlig måte.',
  'RepayLoan.Payment.Letter': 'Last gjerne ned innfrielsesbrevet, eller få det tilsendt på e-post',
  'RepayLoan.Payment.Letter.Title': 'Innfrielsesbrev',
  'RepayLoan.Payment.Letter.Download': 'Last ned innfrielsesbrevet',
  'RepayLoan.Payment.Letter.Send': 'Send på e-post',
  'RepayLoan.Payment.Error': 'Kunne ikke hente betalingsinformasjon',
  'RepayLoan.Error.Missing': 'Velg en av alternativene over',
  'RepayLoan.Error.String': 'Må fylles ut',
  'RepayLoan.Error.Select': 'Velg en av alternativene over',
  'RepayLoan.Error.Number': 'Må fylles ut med tall',
  'RepayLoanCompany.Title': 'Innfri lån',
  'RepayLoanCompany.Back': 'Tilbake',
  'RepayLoanCompany.Date': 'Dato for innfrielse',
  'RepayLoanCompany.Reason': 'Hvorfor innfrir du lånet?',
  'RepayLoanCompany.Reason.Sale': 'Salg av objekt',
  'RepayLoanCompany.Reason.Refinancing': 'Refinansiering',
  'RepayLoanCompany.Reason.Bank': 'Flytte lån til en annen bank',
  'RepayLoanCompany.Reason.Reduce': 'Redusere engasjement',
  'RepayLoanCompany.Reason.Interest': 'Høy rente',
  'RepayLoanCompany.Origin': 'Hvor kommer pengene fra?',
  'RepayLoanCompany.Origin.ToolTip':
    'I henhold til hvitvaskingsregelverket må vi<br/>gjennomføre tiltak for å forhindre hvitvasking,<br/>og kjennskap til midlers opprinnelse er et slikt tiltak.',
  'RepayLoanCompany.Origin.Exchange': 'Innbytte',
  'RepayLoanCompany.Origin.BusinessProfits': 'Overskudd fra bedrift',
  'RepayLoanCompany.Origin.InsuranceSettlement': 'Forsikringsoppgjør',
  'RepayLoanCompany.Origin.StockSale': 'Salg av aksjer',
  'RepayLoanCompany.Payment': 'Hent betalingsinformasjon',
  'RepayLoanCompany.Payment.ChangedDate': 'Dato er justert til neste bankdag.',
  'RepayLoanCompany.Payment.Information': 'Din betalingsinfo',
  'RepayLoanCompany.Payment.Amount': 'Beløp',
  'RepayLoanCompany.Payment.DueDate': 'Forfall',
  'RepayLoanCompany.Payment.AccountNumber': 'Kontonr',
  'RepayLoanCompany.Payment.KIDNumber': 'Kidnr',
  'RepayLoanCompany.Payment.Expires':
    'Hvis lånet ikke innfris, sender vi neste faktura på vanlig måte.',
  'RepayLoanCompany.Payment.Letter': 'Last gjerne ned innfrielsesbrevet',
  'RepayLoanCompany.Payment.Letter.Title': 'Innfrielsesbrev',
  'RepayLoanCompany.Payment.Letter.Download': 'Last ned innfrielsesbrevet',
  'RepayLoanCompany.Error.Missing': 'Velg en av alternativene over',
  'RepayLoanCompany.Error.String': 'Må fylles ut',
  'RepayLoanCompany.Error.Select': 'Velg en av alternativene over',
  'RepayLoanCompany.Error.Number': 'Må fylles ut med tall',
};
