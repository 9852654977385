export const settings = {
  'Settings.GetContactError':
    'Kunne ikke laste inn dine innstillinger. Prøv igjen senere, eller ta kontakt med kundeservice dersom problemet vedvarer.',
  'Settings.Title': 'Din brukerinformasjon',
  'Settings.Info.Header': 'Min kontaktinfo',
  'Settings.Info.Address': 'Adresse',
  'Settings.Info.PostalCode': 'Postnr/sted',
  'Settings.Info.Mobile': 'Mobil',
  'Settings.Info.Phone': 'Telefon',
  'Settings.Info.Email': 'Epost',
  'Settings.Info.Cancel': 'Avbryt',
  'Settings.Info.Save': 'Lagre endringer',
  'Settings.Info.Error.String': 'Må fylles ut',
  'Settings.User': 'Din kontaktinfo',
  'Settings.User.Description': 'Det er ditt ansvar å sørge for at dine opplysninger er riktige',
  'Settings.User.Contact': 'Din kontaktinfo',
  'Settings.User.Name': 'Navn',
  'Settings.Consent.Header': 'Mine samtykker',
  'Settings.Consent.Ads':
    'For å gi meg en god kundeopplevelse ønsker jeg at Brage Finans sender meg relevant informasjon om de produkter og løsninger som selskapet tilbyr.',
  'Settings.Consent.Share':
    'Dersom jeg er kunde i en av eierbankene til Brage Finans, ønsker jeg at informasjon om meg og avtalen(e) mine (herunder bl.a registrerte personopplysninger) kan deles med min(e) eierbank(er). Dette er for å gi meg den beste kundeopplevelsen. Enten via digitale løsninger eller i kontakt med rådgiver.',
  'Settings.Company.User': 'Din bruker',
  'Settings.Company.Contact': 'Bedriftens kontaktinfo',
  'Settings.Company.User.Name': 'Navn',
  'Settings.Company.User.Access': 'Tilgang',
  'Settings.Company.User.Access.Admin': 'Administrator',
  'Settings.Company.User.Access.Write': 'Lese og skrive',
  'Settings.Company.User.Access.Read': 'Lese',
  'Settings.Company.User.Administration.Title': 'Brukeradministrasjon',
  'Settings.Company.User.Administration.Users': 'Brukere',
  'Settings.Company.User.Administration.UserAccess': 'Brukertilgang',
  'Settings.Company.User.Administration.Add': 'Ny bruker',
  'Settings.Company.User.Administration.Edit': 'Rediger bruker',
  'Settings.Company.User.Administration.Modal.OrgNum': 'Org.nummer',
  'Settings.Company.User.Administration.Modal.Company': 'Selskapsnavn',
  'Settings.Company.User.Administration.Modal.User': 'Brukerens navn',
  'Settings.Company.User.Administration.Modal.ID': 'Fødselsnummer',
  'Settings.Company.User.Administration.Modal.UserAccess': 'Brukertilgang',
  'Settings.Company.User.Administration.Modal.Phone': 'Telefon',
  'Settings.Company.User.Administration.Modal.Email': 'E-post',
  'Settings.Company.User.Administration.Delete':
    'Ønsker du å fjerne tilgangen til bedriftskontoen for {user}?',
  'Settings.Company.Description':
    "Bedriften er ansvarlig for å administrere 'Min side' og sørge for at opplysningene som registreres er korrekt.",
  'Settings.Company.Name': 'Bedrift',
  'ContactFormModal.Submit.Edited': 'Endre & lukk',
  'ContactFormModal.Submit.Unedited': 'Riktig!',
  'ContactFormModal.Title': 'Hei, {firstName} {lastName}!',
  'ContactFormModal.First': 'Har vi riktig kontaktinfo om deg?',
  'ContactFormModal.Second':
    'Før vi går videre ber vi deg om å bekrefte at de opplysninger vi har lagret om deg er korrekt.',
  'ContactFormModal.Info.Header': 'Din kontaktinfo',
  'ContactFormModal.Consent.Header': 'Dine samtykker',
  'ContactFormModal.Company.First': 'Har vi riktig kontaktinfo om bedriften?',
  'ContactFormModal.Company.Second':
    'Før du går videre ber vi deg om å bekrefte at de opplysninger vi har lagret om bedriften er korrekt.',
  'ContactFormModal.Company.ToolTip':
    'Om bedriften har byttet navn ta kontakt med kundeservice så hjelper vi deg med å endre',
  'ContactFormModal.Company.Info.Name': 'Bedrift',
};
