import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {sanityClient} from './sanity-client';
import {FlagPosterData} from './types';

function useFlagPosterData(): UseQueryResult<FlagPosterData[]> {
  const query = `*[_type == 'flag-poster' && isEnabled] {_id, text}`;
  return useQuery(['flag-poster-data'], () => sanityClient.fetch(query), {
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

export {useFlagPosterData};
