import {
  AccountPageDataDto,
  AccountPreviewDto,
  AccountStatusDto,
  PaginatedQueryOptions,
  PaginatedResponse,
} from '@brage/dto';
import {AxiosInstance} from 'axios';

export function accountFetchers(client: AxiosInstance) {
  return {
    getAccount: ({orgNo, accountNo}: {orgNo: string | null; accountNo: string}) => {
      const url = orgNo ? `/${orgNo}/accounts/${accountNo}` : `/accounts/${accountNo}`;
      return client.get<AccountPageDataDto>(url).then((r) => r.data);
    },
    getAccountStatus: ({orgNo, accountNo}: {orgNo: string | null; accountNo: string}) => {
      const url = orgNo
        ? `/${orgNo}/accounts/${accountNo}/status`
        : `/accounts/${accountNo}/status`;
      return client.get<AccountStatusDto>(url).then((r) => r.data);
    },
    getAccounts: ({
      orgNo,
      active,
    }: {
      orgNo: string | null;
      active: boolean;
    }): Promise<PaginatedResponse<AccountPreviewDto>> => {
      const url = orgNo ? `/${orgNo}/accounts` : '/accounts';
      return client
        .get<PaginatedResponse<AccountPreviewDto>>(url, {
          params: {active: active},
        })
        .then((r) => r.data);
    },
    getPaginatedAccounts:
      ({orgNo, active}: {orgNo: string | null; active: boolean}) =>
      (opts: PaginatedQueryOptions): Promise<PaginatedResponse<AccountPreviewDto>> => {
        const url = orgNo ? `/${orgNo}/accounts` : '/accounts';
        return client
          .get<PaginatedResponse<AccountPreviewDto>>(url, {
            params: {skip: opts.skip, take: opts.take, active: active},
          })
          .then((r) => r.data);
      },
    getAccountsBuyoutDisabled: (orgNo: string | null) => {
      if (!orgNo) {
        return Promise.resolve(true);
      }
      const url = `/${orgNo}/accounts/buyout-disabled`;
      return client.get<boolean>(url).then((r) => r.data);
    },
  };
}
