import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
};

function AppContainer(props: Props) {
  return <div className="min-h-full w-full flex flex-col relative">{props.children}</div>;
}

export {AppContainer};
