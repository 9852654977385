import {ReactNode} from 'react';
import {useScrollDirection} from '../hooks/useScrollDirection';

type Props = {
  children: ReactNode;
};

function Header(props: Props) {
  const scrollDirection = useScrollDirection();

  return (
    <div
      className={`w-full bg-brage-purple box-border md:py-4 lg:py-8 sticky z-10 transition-all duration-500 ${
        scrollDirection === 'down' ? '-top-36' : 'top-0'
      }`}
    >
      <header className="flex items-center max-w-[1600px] w-full mx-auto box-border h-20">
        {props.children}
      </header>
    </div>
  );
}

export {Header};
