import {InboxDto} from '@brage/dto';
import {AxiosInstance} from 'axios';

export function inboxFetchers(client: AxiosInstance) {
  return {
    getInbox: ({orgNo = ''}: {orgNo?: string | null}) => {
      const url = `${orgNo ? `/${orgNo}` : ''}/inbox`;
      return client.get<InboxDto>(url).then((r) => r.data);
    },
  };
}
